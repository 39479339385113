import { ChoiceListProps } from "@shopify/polaris"
import dayjs from "dayjs"

import { IFilterOptionBase } from "@/api/orders/type"
import { SortButtonDirection } from "@/component"
import { TableItem } from "@/models/IOrders"

export interface FilterParam {
  orderedAt?: OrderedAt
  orderedAtMin?: string
  orderedAtMax?: string
  /** 单号状态 */
  status?: Status
  /** 国家 */
  countries?: string[]
  /** 运输商 */
  couriers?: string[]
  /** 运输时长 */
  transitTime?: [number, number]
  /** 查询字符 */
  filterValue?: string
  /** 排序 */
  orderBy?: OrderBy
  /** 页码 */
  page?: number
}

type OrderedAt = "Today" | "Last 7 days" | "Last 30 days" | "Last 60 days" | "Last 90 days" | "Last 120 days"


type Status =
  "Pending"
  | "In transit"
  | "Out for delivery"
  | "Delivered"
  | "Expired"
  | "Failed attempt"
  | "Exception"
  | "Info received"


type OrderField = string

export enum OrderDirection {
  DESC = -1,
  ASC = 1,
}

export type OrderBy = { [orderField: OrderField]: OrderDirection }


export enum QueryParam {
  orderedAt = "orderedAt",
  orderedAtMin = "orderedAtMin",
  orderedAtMax = "orderedAtMax",
  fulfilledAt = "fulfilledAt",
  fulfilledAtMin = "fulfilledAtMin",
  fulfilledAtMax = "fulfilledAtMax",
  status = "status",
  paypalSyncStatus = "paypalSyncStatus",
  countries = "countries",
  couriers = "couriers",
  lastMile = "lastMileCarrierIds",
  orderTags = "orderTags",
  transitTime = "transitTime",
  residenceTime = "residenceTime",
  Query = "query",
  orderBy = "orderBy",
  page = "page",
}

type Choice = ChoiceListProps["choices"][number]

type ChoicesSelected = string[]

export type QueryParamsType = {
  [k in QueryParam]?: any
}

export enum OrderReSyncStatus {
  Unknown = 0,
  Synchronizing = 10,
  SyncComplete = 20
}

export interface ITabNav {
  // title: {};
  id: string
  /** 文本内容, 会在无需翻译的项中存在, 比如自定义视图 */
  content?: string
  total?: number
  filters_append?: boolean
  filters?: QueryParamsType
  tran_key?: string // 2022-04-20 新加字段
  isCustomized?: boolean
}

export interface IDateOption {
  selected?: string
  starting: dayjs.Dayjs
  ending: dayjs.Dayjs
}

export interface IOrdersState {
  /** 是否为首次加载*/
  isFirstLoad: boolean,

  /** 当前一级导航参数 */
  selectedTabIndex: number
  /** 选中的 tab 项 ID */
  selectedTabId: string

  /** 是否选中自定义 tab */
  isSelectedCustomNav: boolean

  /** 导航项数量 */
  tabsShipmentCount: Record<string, number>

  navTitle: string
  navSaved: boolean

  /** 同步时间选择列表 */
  syncTimeList: any[]

  orderMessage: {
    tableData: TableItem[]
    numberCount: number
  }

  /** 表格页码 */
  tablePageNum: number
  prevPage?: number
  nextPage?: number

  pageConfigLoading: boolean
  ordersLoading: boolean

  queryValue: string

  dateSelected?: string
  dateStartingSelected: dayjs.Dayjs
  dateEndingSelected: dayjs.Dayjs

  // todo dateSelected → orderedDateOption
  // orderedDateOption: IDateOption
  fulfilledDateOption: IDateOption

  statusChoices: CustomChoice[]
  statusSelected: ChoicesSelected
  courierChoices: CustomChoice[]
  lastMileChoices: CustomChoice[]
  courierSelected: ChoicesSelected
  lastMileSelected: ChoicesSelected
  destinationChoices: CustomChoice[]
  destinationSelected: ChoicesSelected
  paypalSyncStatusChoices: CustomChoice[]
  paypalSyncStatusSelected: ChoicesSelected
  orderTagChoices: CustomChoice[]
  /** Order tag 筛选项里的搜索关键词 */
  orderTagsKeyword: string
  /** 搜索结果. 因为需要让 Collapsible 组件重新计算高度, 所以抽离到父组件上进行搜索了 */
  orderTagChoicesShow?: CustomChoice[]
  orderTagSelected: ChoicesSelected
  transitTimeValue?: [number, number]
  residenceTimeValue?: [number, number]

  reSync: {
    /** 数据是否加载完成 */
    loaded: boolean
    count: number// 订单数量
    show: boolean// 是否显示弹窗
    status: OrderReSyncStatus// 同步状态
  }

  // 日期筛选项是否选中了 Custom 项；用于触发组件刷新，使界面正常显示出日期选择器
  isDateFilterCustomItemSelected: boolean

  /** 排序字段 */
  sortSelected: string
  /** 排序方向 */
  sortDirectionSelected: SortButtonDirection

  headingColumnPopoverActive: boolean

  exception_shipment_banner_closed: boolean // shipment_banner是否关闭
}

export type CustomChoice = Choice & Pick<IFilterOptionBase, "tran_key">
