import { useWindowSize } from "@channelwill/hooks"
import {
  Box,
  Button,
  ButtonGroup,
  ButtonProps,
  DatePicker as PopoverDatePicker,
  Divider, Icon,
  OptionList,
  Popover, Select,
  TextField,
} from "@shopify/polaris"
import type { PopoverOverlayProps } from "@shopify/polaris/build/ts/src/components/Popover/components"
import {
  ArrowRightIcon, CalendarIcon,
} from "@shopify/polaris-icons"
import dayjs from "dayjs"
import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import styles from "./style.module.scss"

interface DatePickerProps {
  preferredAlignment?: PopoverOverlayProps["preferredAlignment"];
  buttonProps?: ButtonProps
  prefixText?: string
  onApply?: (selected: number, startDate: Date, endDate: Date) => void
}

const DateFormat = "YYYY-MM-DD"

const VALID_YYYY_MM_DD_DATE_REGEX = /^\d{4}-\d{1,2}-\d{1,2}/

function isDate(date: string | number | Date) {
  return !isNaN(new Date(date).getDate())
}
function isValidYearMonthDayDateString(date: string) {
  return VALID_YYYY_MM_DD_DATE_REGEX.test(date) && isDate(date)
}
function isValidDate(date: string) {
  return date.length === 10 && isValidYearMonthDayDateString(date)
}

export default function DatePicker({
  buttonProps,
  preferredAlignment,
  prefixText,
  onApply,
}: DatePickerProps) {
  const { t } = useTranslation(["dashboard", "common"])
  const { width } = useWindowSize()
  const isSmallScreen = width < 690
  // popover的开启状态和操作函数
  const [popoverActive, setPopoverActive] = useState(false)
  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  )

  const getBeforeDate = (subtract: number) => dayjs().tz()
    .subtract(subtract, "day")
    .format(DateFormat)

  // 初始配置
  const datePickerConfig = {
    /** 当前日期 */
    today: dayjs().tz()
      .format(DateFormat),

    /** 7天前日期 */
    before7: getBeforeDate(6),

    /** 30天前日期 */
    before30: getBeforeDate(29),

    /** 60天前日期 */
    before60: getBeforeDate(59),

    /** 90天前日期 */
    before90: getBeforeDate(89),

    /** 120天前日期 */
    before120: getBeforeDate(119),
  }

  const selectDatesMaps = {
    "1": [dayjs(datePickerConfig.today).toDate(), dayjs(datePickerConfig.today).toDate()],
    "7": [dayjs(datePickerConfig.before7).toDate(), dayjs(datePickerConfig.today).toDate()],
    "30": [dayjs(datePickerConfig.before30).toDate(), dayjs(datePickerConfig.today).toDate()],
    "60": [dayjs(datePickerConfig.before60).toDate(), dayjs(datePickerConfig.today).toDate()],
    "90": [dayjs(datePickerConfig.before90).toDate(), dayjs(datePickerConfig.today).toDate()],
    "120": [dayjs(datePickerConfig.before120).toDate(), dayjs(datePickerConfig.today).toDate()],
  }

  // 默认选择日期
  const [defaultSelect, setDefaultSelect] = useState("60")
  // 当前选择的日期快捷选项
  const [selected, setSelected] = useState<string[]>([defaultSelect])

  // 默认选择日期区间
  const [defaultSelectedDates, setDefaultSelectedDates] = useState({
    start: dayjs(datePickerConfig.before60).toDate(),
    end: dayjs(datePickerConfig.today).toDate(),
  })
  // 当前选择的日期区间
  const [selectedDates, setSelectedDates] = useState(defaultSelectedDates)
  // 当前日期选择器区间
  const [{ month, year }, setDate] = useState<{ month:number, year:number }>({ month: selectedDates.start.getMonth(), year: selectedDates.start.getFullYear() })
  const handleMonthChange = useCallback(
    (month: number, year: number) => setDate({ month, year }),
    [],
  )

  const [startInputValue, setStartInputValue] = useState(dayjs(selectedDates.start).format(DateFormat))
  const [endInputValue, setEndInputValue] = useState(dayjs(selectedDates.end).format(DateFormat))

  /**
   * 选项切换
   */
  const handleSelectChange = useCallback(
    (selected: string[]|string) => {
      if (selected === "custom"){
        setSelected([selected])
        return
      }
      // 当为select时，selected为单个string
      if (!Array.isArray(selected)) {
        selected = [selected]
      }

      const [start, end] = selectDatesMaps[selected[0] as "1"|"7"|"30"|"60"|"90"]

      // 设置select选项
      setSelected(selected)
      // 设置select匹配的日期Range
      setSelectedDates({ start, end })

      // 改变输入框内容
      setStartInputValue(dayjs(start).format(DateFormat))
      setEndInputValue(dayjs(end).format(DateFormat))

      handleMonthChange(start.getMonth(), start.getFullYear())
    }, [])

  /**
   * 日期区间选择
   * @param date
   */
  const handleDateRangeChange = (date: {start:Date, end:Date}) => {
    setStartInputValue(dayjs(date.start).format(DateFormat))
    setEndInputValue(dayjs(date.end).format(DateFormat))

    if (dayjs(date.end).format(DateFormat) === datePickerConfig.today){
      const startDate = dayjs(date.start).format(DateFormat)

      switch (startDate){
        case datePickerConfig.today:
          setSelected(["1"])
          handleMonthChange(date.start.getMonth(), date.start.getFullYear())
          break
        case datePickerConfig.before7:
          setSelected(["7"])
          handleMonthChange(date.start.getMonth(), date.start.getFullYear())
          break
        case datePickerConfig.before30:
          setSelected(["30"])
          handleMonthChange(date.start.getMonth(), date.start.getFullYear())
          break
        case datePickerConfig.before60:
          setSelected(["60"])
          handleMonthChange(date.start.getMonth(), date.start.getFullYear())
          break
        case datePickerConfig.before90:
          setSelected(["90"])
          handleMonthChange(date.start.getMonth(), date.start.getFullYear())
          break
        case datePickerConfig.before120:
          setSelected(["120"])
          handleMonthChange(date.start.getMonth(), date.start.getFullYear())
          break
        default:
          setSelected([])
          handleMonthChange(date.start.getMonth(), date.start.getFullYear())
      }
    } else {
      setSelected([])
      handleMonthChange(date.start.getMonth(), date.start.getFullYear())
    }

    setSelectedDates(date)
  }

  function handleStartInputValueChange(value:string) {
    setStartInputValue(value)

    if (isValidDate(value)) {
      let start = value, end = endInputValue

      if (dayjs(value).isBefore(datePickerConfig.before120)) {
        start = datePickerConfig.before120
      }
      if (dayjs(value).isAfter(datePickerConfig.today)) {
        start = datePickerConfig.today
      }

      if (dayjs(start).isAfter(end)){
        end = start
      }

      handleDateRangeChange({
        start: dayjs(start).toDate(),
        end: dayjs(end).toDate(),
      })
    }
  }

  function handleEndInputValueChange(value:string) {
    setEndInputValue(value)

    if (isValidDate(value)) {
      let start = startInputValue, end = value

      if (dayjs(end).isBefore(datePickerConfig.before120)) {
        end = datePickerConfig.before120
      }
      if (dayjs(end).isAfter(datePickerConfig.today)) {
        end = datePickerConfig.today
      }

      if (dayjs(end).isBefore(start)){
        start = end
      }

      handleDateRangeChange({
        start: dayjs(start).toDate(),
        end: dayjs(end).toDate(),
      })
    }
  }
  // 起始日期输入框失焦事件
  function handleStartInputValueBlur() {
    handleStartInputValueChange(dayjs(startInputValue).format(DateFormat))
  }
  // 结束日期输入框失焦事件
  function handleEndInputValueBlur() {
    handleEndInputValueChange(dayjs(endInputValue).format(DateFormat))
  }

  const selectOptions = [
    { value: "1", label: t("dashboard:DatePicker.today") },
    { value: "7", label: t("dashboard:DatePicker.lastDays", { day: 7 }) },
    { value: "30", label: t("dashboard:DatePicker.lastDays", { day: 30 }) },
    { value: "60", label: t("dashboard:DatePicker.lastDays", { day: 60 }) },
    { value: "90", label: t("dashboard:DatePicker.lastDays", { day: 90 }) },
    { value: "120", label: t("dashboard:DatePicker.lastDays", { day: 120 }) },
  ]

  let buttonValue = ""

  if (defaultSelect){
    buttonValue = selectOptions.find(item => item.value === defaultSelect)?.label || ""
  } else {
    buttonValue = dayjs(defaultSelectedDates.start).format("MMM D, YYYY") + " - " +  dayjs(defaultSelectedDates.end).format("MMM D, YYYY")
  }

  const handleApply = () => {
    setDefaultSelect(selected.length > 0 ? selected[0] : "")
    setDefaultSelectedDates(selectedDates)
    setPopoverActive(false)

    onApply && onApply(Number(selected[0] || 0), selectedDates.start, selectedDates.end)
  }
  const handleCancel = () => {
    setPopoverActive(false)
  }

  useEffect(() => {
    if (popoverActive){
      setStartInputValue(dayjs(selectedDates.start).format(DateFormat))
      setEndInputValue(dayjs(selectedDates.end).format(DateFormat))
    } else {
      // 关闭-恢复默认
      setSelected([defaultSelect])
      setSelectedDates(defaultSelectedDates)
    }
  }, [popoverActive])

  return <div>
    <Popover
      fluidContent
      active={popoverActive}
      activator={
        <div className={buttonProps?.variant === "secondary" ? "Polaris-ActionMenu-SecondaryAction" : ""}>
          <Button icon={CalendarIcon} {...buttonProps} onClick={togglePopoverActive}>{prefixText || ""}{buttonValue}</Button>
        </div>
      }
      onClose={togglePopoverActive}
      ariaHaspopup={false}
      preferredAlignment={preferredAlignment}
    >
      <div className={"max-w-[681px]"}>

        {
          // 移动端下的select框
          isSmallScreen &&
            <div className={"pt-4 px-4"}>
              <Select
                label=""
                options={[...selectOptions, { value: "custom", label: t("dashboard:DatePicker.custom") }]}
                onChange={handleSelectChange}
                value={selected[0] || "custom"}
              />
            </div>
        }

        <div className={styles.optionsListWrapper}>
          {/* 非移动端下的OptionList快捷选择 */}
          {
            !isSmallScreen &&
            <Box width={"133px"}>
              <OptionList
                allowMultiple={false}
                onChange={handleSelectChange}
                options={selectOptions}
                selected={selected}
              />
            </Box>
          }
          <Box padding={"400"} width={"548px"}>
            <div className={"flex gap-2"}>
              <div className={"flex-1"}>
                <TextField
                  labelHidden
                  value={startInputValue}
                  placeholder={"YYYY-MM-DD"}
                  onChange={handleStartInputValueChange}
                  onBlur={handleStartInputValueBlur}
                  autoComplete="off"
                  label={""}
                />
              </div>
              <Icon tone={"subdued"} source={ArrowRightIcon} />
              <div className={"flex-1"}>
                <TextField
                  labelHidden
                  value={endInputValue}
                  placeholder={"YYYY-MM-DD"}
                  onChange={handleEndInputValueChange}
                  onBlur={handleEndInputValueBlur}
                  autoComplete="off"
                  label={""}
                />
              </div>
            </div>

            <div className={"mt-4"}>
              <PopoverDatePicker
                month={month}
                year={year}
                onChange={handleDateRangeChange}
                onMonthChange={handleMonthChange}
                disableDatesBefore={dayjs(datePickerConfig.before120).toDate()}
                disableDatesAfter={dayjs(datePickerConfig.today).toDate()}
                selected={selectedDates}
                multiMonth={!isSmallScreen}
                allowRange
              />
            </div>
          </Box>
        </div>

        <Divider borderColor="border" />

        <Box padding={"400"}>
          <div className={"flex justify-end"}>
            <ButtonGroup>
              <Button onClick={handleCancel}>{t("common:Cancel")}</Button>
              <Button
                variant="primary"
                disabled={defaultSelectedDates.start.toDateString() === selectedDates.start.toDateString() && defaultSelectedDates.end.toDateString() === selectedDates.end.toDateString()}
                onClick={handleApply}
              >{t("common:Apply")}</Button>
            </ButtonGroup>
          </div>
        </Box>
      </div>
    </Popover>
  </div>
}
