import { Button, InlineStack, Link, Text, Tooltip } from "@shopify/polaris"
import { ClipboardIcon } from "@shopify/polaris-icons"
import classNames from "classnames"
import copy from "copy-to-clipboard"
import React from "react"

import styles from "./CourierInfo.module.scss"

export interface CourierInfoProps {
  courierName: string
  courierLogoURL: string
  courierURL: string
  trackingNumber: string
  copyTrackingNumberTooltip: string

  onCopyTrackingNumber(copyStatus: boolean): void
}

export default function CourierInfo({
  courierName,
  courierLogoURL,
  courierURL,
  trackingNumber,
  copyTrackingNumberTooltip,
  onCopyTrackingNumber,
}: CourierInfoProps) {
  return (
    <div className={styles.CourierInfo}>
      <div>
        <a href={courierURL} target="_blank" rel="nofollow noreferrer">
          <img
            src={courierLogoURL}
            alt={`${courierName} Logo`}
          />
        </a>
      </div>

      <div className={styles.CourierInfo__Info}>
        <Link url={courierURL} target={"_blank"} removeUnderline><p className={styles.courierNameTrackNumber}>{courierName}</p></Link>

        <div className={classNames(styles.CourierInfo__Info_TrackNumber, courierLogoURL ? styles.TrackNumber : "")}>
          <Text as="p" variant="bodyMd">{trackingNumber}</Text>
          <Tooltip
            content={<InlineStack gap="200">{copyTrackingNumberTooltip}</InlineStack>}
          >
            <Button
              icon={ClipboardIcon} variant={"plain"}
              onClick={() => onCopyTrackingNumber(copy(trackingNumber))}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  )
}
