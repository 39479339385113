import { Badge, Button, InlineStack, Link, Text, Tooltip } from "@shopify/polaris"
import { ClipboardIcon } from "@shopify/polaris-icons"
import classNames from "classnames"
import copy from "copy-to-clipboard"
import React from "react"
import { useTranslation } from "react-i18next"

import styles from "./CourierInfo.module.scss"

export interface CourierInfoProps {
  courierName?: string | null
  courierLogoURL?: string  | null
  courierURL?: string | null
  trackingNumber?: string  | null
  copyTrackingNumberTooltip: string

  onCopyTrackingNumber(copyStatus: boolean): void
}

export default function LastMileCourierInfo({
  courierName,
  courierLogoURL,
  courierURL,
  trackingNumber,
  copyTrackingNumberTooltip,
  onCopyTrackingNumber,
}: CourierInfoProps) {
  const { t } = useTranslation("orders")

  const logoNode = courierLogoURL ? <img
    src={courierLogoURL}
    alt={`${courierName} Logo`}
  /> : null

  return (
    <div className={styles.CourierInfo}>
      {
        courierLogoURL && <div className={"flex"}>
          {courierURL ? <a href={courierURL} target="_blank" rel="nofollow noreferrer">{logoNode}</a> : logoNode}
        </div>
      }

      <div className={styles.CourierInfo__Info}>
        <div className={"flex gap-1 items-center"}>
          {
            courierName ? courierURL ? <Link url={courierURL} target={"_blank"} removeUnderline><p className={classNames(styles.courierName, courierLogoURL ? styles.courierName : styles.noLogoCourierName)}>{courierName}</p></Link>
              : <p className={classNames(styles.CourierInfoTitle, courierLogoURL ? styles.courierName : styles.noLogoCourierName )}>{courierName}</p> : null
          }
          {(courierName || trackingNumber) && <Badge tone={"info"}>{courierName ? t("LastMileCarrier") : t("LastMileTrackingNumber")}</Badge>}
        </div>

        {
          !!trackingNumber && <div className={classNames(styles.CourierInfo__Info_TrackNumber, courierLogoURL ? styles.TrackNumber : "")}>
            <Text as="p" variant="bodyMd">{trackingNumber}</Text>
            <Tooltip
              width={"wide"}
              content={<InlineStack gap="200">{copyTrackingNumberTooltip}</InlineStack>}
            >
              <Button
                icon={ClipboardIcon} variant={"plain"}
                onClick={() => onCopyTrackingNumber(copy(trackingNumber))}
              />
            </Tooltip>
          </div>
        }
      </div>
    </div>
  )
}
