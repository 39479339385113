import toast from "@/utils/toast"


type Msgs = { [key in number | "*"]?: string }

/**
 * 请求函数错误或正确消息处理中间件
 * @param request request函数
 * @param msgs 错误信息
 * @returns request函数返回值
 */
export async function RequestMessageMiddleware<F>(
  request: F,
  msgs?: Msgs,
): Promise<Awaited<F>> {
  const res = await request

  messageHandle(res as any, msgs)
  return res
}




// 处理错误消息
function messageHandle(res: any, msgs?: Msgs) {
  const isError = res.data.code !== 200


  const successMatch:string = msgs?.["200"] ?? ""
  // 200的正确提示

  if (!isError && successMatch) {
    toast(successMatch, 2400, false)
    return
  }

  // 命中自定义错误信息
  const errorMatch = msgs?.[res.data.code] ?? msgs?.["*"]

  if (isError && errorMatch) {
    toast(errorMatch, 2400, true)
    return
  }

  // 没有消息命中自定义错误信息但是报错了
  if (isError && !errorMatch) {
    toast(res.data.msg, 2400, isError)
  }
}
