import { Page } from "@shopify/polaris"
import { Observer } from "mobx-react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"

import accountApi from "@/api/account"
import { AppPricingInterval } from "@/api/account/type.d"
import { Container } from "@/component"
import { SwitchTab } from "@/component/SwitchTab"
import { ApplyDiscountCodeModal, useApplyDiscountCodeModal } from "@/pages/Account/components/ApplyDiscountCode"
import DescCards from "@/pages/Account/components/DescCards"
import PlanList from "@/pages/Account/components/PlanList"
import { clearPathnameQuery } from "@/router/history"
import stores from "@/stores"
import toast from "@/utils/toast"

import AccountBanners from "./components/Banners"

const Account = () => {
  const { t } = useTranslation("account")
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const { upgradeStore, userInfoSore } = stores
  const { isOpen, openModalAndInitDiscountCode, closeModal } = useApplyDiscountCodeModal()

  const updateCurrentSubscription = () => {
    accountApi.getCurrentSubscription()
      .then((res) => {
        const { current_discount } = userInfoSore.data

        upgradeStore.setCurrentPlan(res.data.current_subscription, current_discount ? current_discount.interval : undefined)
      })
      .then(() => {
        setLoading(false)
      })
  }

  // const openUpgradeModal = () => setShowUpgradeModal(true)
  const openUpgradeModal = () => toast(t("UpgradedSuccessfully"))

  useEffect(() => {
    window.scrollTo({ top: 0 })
    setLoading(true)
    updateCurrentSubscription()
    // 如果没请求过plans，获取套餐列表
    if (!upgradeStore.initialized) upgradeStore.updatePlans()
  }, [])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    // 降级套餐成功
    const charge_result = searchParams.get("charge_result")

    switch (charge_result) {
      // 升级成功
      case "upgrade_success" :
        openUpgradeModal()
        break
      // 降级成功
      case "downgrade_success" :
        clearPathnameQuery("/account")
        toast(t("DowngradedSuccessfully"))
        break
      default:
        break
    }
  }, [location.search])

  return <Observer>{() =>
    <Container maxWidth={"1036px"} id="Page-Account">
      <ApplyDiscountCodeModal open={isOpen} onClose={closeModal} t={t} />

      <Page
        title={t("ChangePlan")}
        backAction={{
          onAction: () => navigate(stores.commonStore.accountSource),
        }}
        secondaryActions={[
          {
            content: t("ApplyDiscountCode") as string,
            onAction() {
              openModalAndInitDiscountCode()
            },
          },
        ]}
      />

      <SwitchTab
        className="w-max-content mx-auto"
        value={upgradeStore.payType}
        radioOptions={[
          { label: t("PayMonthly"), value: AppPricingInterval.Every30_Days },
          { label: t("PayAnnuallySave20"), value: AppPricingInterval.Annual },
        ]}
        handleRadio={(value: string) => upgradeStore.setPayType(value as AppPricingInterval)}
      />

      {/* banner区域, 统一由此组件管理 */}
      <AccountBanners currentSubscription={upgradeStore.currentPlan} />

      <PlanList
        payType={upgradeStore.payType}
        currentSubscription={upgradeStore.currentPlan}
        updateCurrentSubscription={updateCurrentSubscription}
        plans={upgradeStore.plans}
        loading={loading || !upgradeStore.initialized}
        openUpgradeModal={openUpgradeModal}
        // disabledSubscription={userInfoSore.data.subscription_payment_method === 1}
        subscriptionPaymentMethod={userInfoSore.data.subscription_payment_method}
      />

      <div className="mt-4" />

      {/* 描述卡片区域*/}
      <DescCards />

      {/* 升级后显示弹窗*/}
      {/* <UpgradedSuccessModal*/}
      {/*  show={showUpgradeModal}*/}
      {/*  onClose={() => {*/}
      {/*    clearPathnameQuery("/account")*/}
      {/*    setShowUpgradeModal(false)*/}
      {/*  }}*/}
      {/* />*/}
    </Container>
  }</Observer>
}

export default Account
