import { Redirect } from "@shopify/app-bridge/actions"
import { isShopifyEmbedded } from "@shopify/app-bridge-utils"
import { BlockStack, Box, Button, Card, Icon, Modal, RangeSlider, Text, Tooltip } from "@shopify/polaris"
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from "@shopify/polaris-icons"
import classNames from "classnames"
import classnames from "classnames"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import accountApi from "@/api/account"
import {
  AppPricingInterval,
  CurrentSubscription,
  DiscountStyleEnum,
  PayType,
  Plan,
  PlanPricing,
} from "@/api/account/type.d"
import Discount50 from "@/assets/svg/PlanDiscount/Discount50"
import CustomPlan from "@/pages/Account/components/CustomPlan"
import DowngradeModal from "@/pages/Account/components/DowngradeModal"
import PlanSkeleton from "@/pages/Account/components/PlanList/PlanSkeleton"
import PriceBlock from "@/pages/Account/components/PriceBlock"
import stores from "@/stores"
import { formatNumber } from "@/utils/analyticsUtils"
import AppBridge from "@/utils/appBridge"
import toast from "@/utils/toast"

import styles from "./index.module.scss"
import SubjectText from "./SubjectText"

type plansProps = {
  updateCurrentSubscription: () => void,
  currentSubscription: CurrentSubscription
  plans: Plan[]
  loading: boolean
  disabledSubscription?: boolean
  openUpgradeModal: () => void
  payType: PayType
  subscriptionPaymentMethod?: number
}

const PlanList = ({
  payType,
  currentSubscription,
  updateCurrentSubscription,
  plans,
  loading,
  openUpgradeModal,
  disabledSubscription,
  subscriptionPaymentMethod,
}: plansProps) => {
  const { t } = useTranslation(["account", "common"])
  const navigator = useNavigate()
  const ComparisonData = [
    {
      title: t("account:ComparisonGroup1.title"),
      list: [
        [t("account:ComparisonGroup1.item1"), true, true, true, true],
        [t("account:ComparisonGroup1.item2"), true, true, true, true],
        [t("account:ComparisonGroup1.item3"), true, true, true, true],
        [t("account:ComparisonGroup1.item4"), true, true, true, true],
        [t("account:ComparisonGroup1.item5"), true, true, true, true],
        [t("account:ComparisonGroup1.item6"), true, true, true, true],
        [t("account:ComparisonGroup1.item7"), false, false, true, true],
        [t("account:ComparisonGroup1.item8"), false, false, false, true],
      ],
    },
    {
      title: t("account:ComparisonGroup2.title"),
      list: [
        [t("account:ComparisonGroup2.item1"), true, true, true, true],
        [t("account:ComparisonGroup2.item2"), true, true, true, true],
        [t("account:ComparisonGroup2.item3"), true, true, true, true],
        [t("account:ComparisonGroup2.item4"), true, true, true, true],
        [[t("account:ComparisonGroup2.item5"), t("account:ComparisonGroup2.description5")], true, true, true, true],
        [[t("account:ComparisonGroup2.item6"), t("account:ComparisonGroup2.description6")], true, true, true, true],
        [t("account:ComparisonGroup2.item7"), true, true, true, true],
        [t("account:ComparisonGroup2.item8"), false, true, true, true],
        [t("account:ComparisonGroup2.item9"), false, true, true, true],
        [t("account:ComparisonGroup2.item10"), false, true, true, true],
        [t("account:ComparisonGroup2.item11"), false, false, true, true],
        [t("account:ComparisonGroup2.item12"), false, false, true, true],
      ],
    },
    {
      title: t("account:ComparisonGroup3.title"),
      list: [
        [t("account:ComparisonGroup3.item1"), false, true, true, true],
        [t("account:ComparisonGroup3.item2"), false, true, true, true],
        [t("account:ComparisonGroup3.item3"), false, true, true, true],
        [t("account:ComparisonGroup3.item4"), false, true, true, true],
        [t("account:ComparisonGroup3.item5"), false, true, true, true],
        [t("account:ComparisonGroup3.item6"), false, true, true, true],
        [t("account:ComparisonGroup3.item7"), false, true, true, true],
        [t("account:ComparisonGroup3.item8"), false, true, true, true],
      ],
    },
    {
      title: t("account:ComparisonGroup4.title"),
      list: [
        [t("account:ComparisonGroup4.item1"), false, true, true, true],
        [t("account:ComparisonGroup4.item2"), false, true, true, true],
      ],
    },
    {
      title: t("account:ComparisonGroup5.title"),
      list: [
        [t("account:ComparisonGroup5.item1"), true, true, true, true],
        [t("account:ComparisonGroup5.item2"), false, true, true, true],
        [t("account:ComparisonGroup5.item3"), false, true, true, true],
        [t("account:ComparisonGroup5.item4"), false, true, true, true],
        [t("account:ComparisonGroup5.item5"), false, false, true, true],
      ],
    },
    {
      title: t("account:ComparisonGroup6.title"),
      list: [
        [t("account:ComparisonGroup6.item1"), true, true, true, true],
        [t("account:ComparisonGroup6.item2"), true, true, true, true],
        [t("account:ComparisonGroup6.item3"), true, true, true, true],
        [t("account:ComparisonGroup6.item4"), false, false, true, true],
        [t("account:ComparisonGroup6.item5"), false, false, true, true],
        [t("account:ComparisonGroup6.item6"), false, false, true, true],
        [t("account:ComparisonGroup6.item7"), false, false, true, true],
        [t("account:ComparisonGroup6.item8"), false, false, true, true],
        [t("account:ComparisonGroup6.item9"), false, false, false, true],
      ],
    },
    {
      title: t("account:ComparisonGroup7.title"),
      list: [
        [t("account:ComparisonGroup7.item1"), true, true, true, true],
        [t("account:ComparisonGroup7.item2"), true, true, true, true],
        [t("account:ComparisonGroup7.item3"), true, true, true, true],
      ],
    },
    {
      title: t("account:ComparisonGroup8.title"),
      list: [
        [t("account:ComparisonGroup8.item1"), true, true, true, true],
        [t("account:ComparisonGroup8.item2"), true, true, true, true],
        [t("account:ComparisonGroup8.item3"), true, true, true, true],
        [t("account:ComparisonGroup8.item4"), false, false, false, true],
        [t("account:ComparisonGroup8.item5"), false, false, false, true],
        [t("account:ComparisonGroup8.item6"), false, false, false, true],
      ],
    },
  ]

  const { next_plan } = currentSubscription
  const [showDowngradeModal, setShowDowngradeModal] = useState(false)
  const [chosen, setChosen] = useState<number | null>(null)
  const [expand, setExpand] = useState(false) // 完整功能展开条件
  const [tempPlanInfo, setTempPlanInfo] = useState<{
    level: number,
    planName: string,
    activePrice: PlanPricing,
    isLowYearToHighMonth: boolean
    isHighMonthToLowerYear: boolean
  } | null>(null)
  const [planSliderValue, setPlanSliderValue] = useState<Record<string, number>>({})
  const [showBundleChangePlanReminder, setShowBundleChangePlanReminder] = useState(false)


  const makeInitPlanSliderValue = () => {
    const initPlanSliderValue: Record<string, number> = {}

    plans.forEach(plan => {
      const isFreePricing = plan.level === 1
      const planPricing = isFreePricing ? plan.pricing : plan.pricing.filter(pricingFilter)

      planPricing.forEach((price, index) => {
        // 初始化用户当前套餐自定义等级
        if (price.id === currentSubscription.pricing_id && currentSubscription.version >= 3) {
          initPlanSliderValue[plan.name] = index
        }
        // 存在下一生效套餐时
        if (currentSubscription.next_plan && price.id === currentSubscription.next_plan.pricing_id) {
          initPlanSliderValue[plan.name] = index
        }

        // 解决云效bug id： EEZZ-2532
        if (
          currentSubscription.version === 3 // 套餐版本3
          && !price.is_custom // 非自定义套餐
          && currentSubscription.level === price.level // 非自定义套餐
          && currentSubscription.quota_total === price.quota // quota_total相等
        ) {
          initPlanSliderValue[plan.name] = index
        }
      })
    })
    return initPlanSliderValue
  }

  useEffect(() => {
    // 当支付年/月Switch切换时，重置到index为零，防止出现Index对不齐-报错
    setPlanSliderValue(payType === currentSubscription.interval ? makeInitPlanSliderValue() : {})
  }, [payType])

  // 过滤年/月费
  const pricingFilter = (pricing: PlanPricing) => pricing.interval === payType

  useEffect(() => {
    // 初始化值容器
    setPlanSliderValue({ ...planSliderValue, ...makeInitPlanSliderValue() })
  }, [plans, currentSubscription.pricing_id])

  // 降低套餐提示
  const handleDowngradeAlertModalConfirm = () => {
    setShowDowngradeModal(false)
    tempPlanInfo && handlePlanChoose(tempPlanInfo.level, tempPlanInfo.planName, tempPlanInfo.activePrice, true)
  }

  // 套餐选择点击
  const handlePlanChoose = (level: number, planName: string, activePrice: PlanPricing, force = false) => {
    // Bundle套餐切换
    if (subscriptionPaymentMethod === 1) {
      setShowBundleChangePlanReminder(true)
      return
    }
    const { id } = activePrice

    // 升套餐版本的降级（低版本年费 → 高版本月费）
    const isLowYearToHighMonth = currentSubscription.interval === AppPricingInterval.Annual
      && activePrice.interval === AppPricingInterval.Every30_Days
      && level > currentSubscription.level

    // 降套餐版本的升级（高版本月费 → 低版本年费）
    const isHighMonthToLowerYear = currentSubscription.interval === AppPricingInterval.Every30_Days
      && activePrice.interval === AppPricingInterval.Annual
      && level < currentSubscription.level

    // 判断是否为降级, 价格项中comparison字段为-1是表示降级，0，1表示升级
    const isDowngrade = level <= currentSubscription.level && activePrice.comparison === -1
      // 升套餐版本的降级（低版本年费 → 高版本月费）
      || isLowYearToHighMonth
      || isHighMonthToLowerYear

    /* 选择免费套餐，弹出窗口确认(降级) */
    if (!force && isDowngrade) {
      setShowDowngradeModal(true)
      // 临时存储刚刚选中的降级套餐
      setTempPlanInfo({ level, planName, activePrice, isLowYearToHighMonth, isHighMonthToLowerYear })
      return
    }

    setChosen(level)

    // 请求订阅接口
    accountApi.createSubscription({
      pricing_id: id,
    })
      .then(({ data: { subscription } }) => {
        // 如果存在需要跳转的链接，那么就前去跳转
        if (subscription && subscription.confirmation_url) {
          // 如果存在 ShopifyBridge 对象的话，就使用它的方法调用
          if (AppBridge.exist()) {
            const redirect = Redirect.create(AppBridge.app)

            redirect.dispatch(Redirect.Action.REMOTE, subscription.confirmation_url)
          } else {
            // 否则用 Window 对象调用打开页面
            window.open(subscription.confirmation_url)
          }
          return
        }

        // 套餐切换 关闭套餐异常提示
        if (stores.userInfoSore.data.show_account_tip_a || stores.userInfoSore.data.show_account_tip) {
          stores.userInfoSore.setDataKey("show_account_tip", false)
          stores.userInfoSore.setDataKey("show_account_tip_a", false)
        }

        // 如果套餐是免费套餐，重新获取用户和套餐信息
        if (planName === "Free") {
          // 老免费套餐 切到新免费套餐时，视为升级
          currentSubscription.level === 0
            ? openUpgradeModal()
            : toast(t("account:DowngradedSuccessfully"))

          updateCurrentSubscription()
        }
      })
      .catch(() => {
        setChosen(null)
      })
      .finally(() => {
        stores.userInfoSore.updateUserInfo()
        setTimeout(() => {
          setChosen(null)
        }, 5000)
      })

  }

  return <>
    <div className={"flex flex-wrap text-center"}>
      {
        loading
          ? <PlanSkeleton />
          : plans.map(plan => {
            // 是免费套餐
            const isFreePricing = plan.level === 1

            const nowPlan = planSliderValue[plan.name] ?? 0 // ?

            const planPricing = isFreePricing ? plan.pricing : plan.pricing.filter(pricingFilter)

            const activePrice = planPricing[nowPlan] || planPricing[0]

            // 是否显示slider条
            const hasSlider = planPricing.length > 1

            // 当前card是用户的当前套餐
            const isActivePricing = activePrice.id === currentSubscription.pricing_id

            // 当前套餐是用户的下一套餐
            const isNextPricing = activePrice.id === next_plan?.pricing_id

            // 禁用订阅套餐按钮
            const disabledSubscribeButton = isNextPricing // 当前套餐是next套餐
              || currentSubscription.version >= 3 && isActivePricing && !activePrice.has_discount_code // 当前版本为3且价格为当前价格
              || isFreePricing && isActivePricing // 当前套餐为免费套餐

            return (
              <div
                key={plan.name}
                className={classNames(styles.PlanList__ItemWrapper, "relative w-full md:w-1/2 lg:w-1/4 mt-4")}
              >
                {/* 折扣徽标*/}
                {activePrice.discount_style && <div style={{ position: "absolute", top: 0, left: 0, zIndex: 1 }}>
                  {activePrice.discount_style === DiscountStyleEnum.Discount50 && <Discount50 />}
                </div>}

                {/* 推荐标签*/}
                {plan.is_recommended && <div
                  className={classNames(styles.Recommended, "absolute uppercase text-white bg-[#1FB9B6] text-xs font-semibold pt-[2px] pb-[2px]")}
                >
                  <Text as="span" variant="bodySm" fontWeight="medium">{t("account:MostPopular")}</Text>
                </div>}

                <div className={classNames(styles.PlanList__Item)}>
                  <div className="pt-8">
                    <Text as="h2" variant="headingMd">{plan.name}</Text>
                  </div>

                  <Box
                    paddingInline="400"
                    paddingBlockEnd="400"
                    paddingBlockStart="100"
                  >
                    <BlockStack gap="400">

                      {/* 英文会有两行，中文下面可能一行也可能两行，为了高度统一，设置高度为两行的高度*/}
                      <SubjectText content={plan.introduction} />

                      {/* 价格块*/}
                      <PriceBlock
                        price_set={activePrice.price_set}
                        discount_price_set={activePrice.discount_price_set}
                      />

                      {
                        hasSlider && (
                          <div>
                            <RangeSlider
                              label={null}
                              max={planPricing.length - 1}
                              value={nowPlan}
                              prefix={<p>{formatNumber(planPricing[0].quota)}</p>}
                              suffix={<p>{formatNumber(planPricing[planPricing.length - 1].quota)}</p>}
                              onChange={(value: number) => {
                                setPlanSliderValue({
                                  ...planSliderValue,
                                  [plan.name]: value,
                                })
                              }}
                              disabled={loading}
                            />
                          </div>
                        )
                      }

                      <div className="space-y-1">
                        <div>
                          <Text
                            as="span"
                            variant="headingMd"
                          >{formatNumber(activePrice.quota)}</Text>
                          {" "}
                          <Text as="span" variant="bodyMd" tone="subdued">{t("account:quota")}</Text>
                          /
                          <Text as="span" variant="bodyMd" tone="subdued">
                            {!isFreePricing && payType === AppPricingInterval.Annual ? t("common:Year").toLowerCase() : t("common:Month").toLowerCase()}
                          </Text>
                        </div>
                        {
                          hasSlider
                            ? <div className="text-xs">
                              <Text
                                as="span"
                                variant="bodySm"
                                tone="subdued"
                              >
                                {t("account:avgPerOrder", {
                                  unitPrice: activePrice.discount_price_set
                                    ? activePrice.discount_price_set.unit_price_amount
                                    : activePrice.price_set.unit_price_amount,
                                })}
                              </Text>
                            </div>
                            : <div
                              className="text-xs"
                              style={{ width: "100%", height: 52 }}
                            >
                              {/* 内容占位*/}
                            </div>
                        }
                      </div>

                      <BlockStack gap="100">
                        {
                          disabledSubscribeButton
                            ? (
                              <Button
                                size="large"
                                fullWidth
                                disabled
                              >
                                {isActivePricing ? t("account:CurrentPlan") : t("account:NextPlan")}
                              </Button>
                            ) : (
                              <Button
                                size="large"
                                fullWidth
                                variant={!isFreePricing ? "primary" : undefined}
                                disabled={loading || disabledSubscription}
                                onClick={() => handlePlanChoose(plan.level, plan.name, activePrice)}
                                loading={chosen === plan.level}
                              >
                                {
                                  // 当前套餐则显示CurrentPlan
                                  activePrice.trial_days
                                    ? t("common:StartTrialDaysFree", { trialDays: activePrice.trial_days })
                                    : t("account:ChooseThisPlan")
                                }
                              </Button>
                            )
                        }

                        <div
                          className={"text-left"}
                        >
                          {
                            isFreePricing || payType === AppPricingInterval.Annual
                              ? <Text
                                tone="subdued"
                                as={"span"}
                                variant="bodyMd"
                              >
                                {t("account:UnavailableAfterExceeded")}
                              </Text>
                              : <Text
                                as="span"
                                tone="subdued"
                                variant="bodyMd"
                              >
                                {t("account:AdditionalQuota", { count: activePrice.additional_quota_price })}
                              </Text>
                          }
                        </div>
                      </BlockStack>

                      {/* 套餐权限描述*/}
                      <div className={styles.PlanAction}>
                        {plan.feature_header && <Text as="p" variant="bodyMd" fontWeight="medium">{plan.feature_header}</Text>}
                        {
                          plan.features_overview.map((overview, i) => (
                            <div
                              key={i}
                              className={styles.FeatureItem}
                            >
                              <div
                                className={classNames(
                                  styles.tickWrapper,
                                  currentSubscription.level >= plan.level
                                    ? styles.successColor
                                    : styles.defaultColor)}
                              >
                                <Icon source={CheckIcon} />
                              </div>
                              <span>{overview}</span>
                            </div>
                          ))
                        }
                      </div>

                      {/* Contact sales 入口, Request custom Enterprise plan 弹窗*/}
                      {plan.level === 4 && <CustomPlan />}
                    </BlockStack>
                  </Box>
                </div>
              </div>
            )
          })
      }
    </div>

    <div className={"mt-6"}>
      <div
        className={"flex cursor-pointer m-auto w-max gap-1 items-center"}
        onClick={() => setExpand(!expand)}
      >
        <Text as="h2" variant="headingLg" fontWeight="regular">{t("account:FullComparison")}</Text>
        <Icon
          tone={"base"}
          source={expand ? ChevronUpIcon : ChevronDownIcon}
        />
      </div>

      {
        expand && <Card padding="0">
          <div
            className={styles.tableCardWrapper}
          >
            <div
              className={styles.tableBody}
            >
              <div
                className={styles.tableCardHeader}
                style={{ top: !window.isFromAdmin && isShopifyEmbedded() ? 0 : 44 }}
              >
                <div className={styles.nameCol} />
                {
                  plans.map(plan => {
                    const isFreePricing = plan.level === 1

                    const nowPlan = planSliderValue[plan.name] ?? 0

                    const planPricing = isFreePricing ? plan.pricing : plan.pricing.filter(pricingFilter)

                    const activePrice = planPricing[nowPlan]

                    // 当前card是用户的当前套餐
                    const isActivePricing = activePrice.id === currentSubscription.pricing_id

                    // 当前套餐是用户的下一套餐
                    const isNextPricing = activePrice.id === next_plan?.pricing_id

                    // 禁用订阅套餐按钮
                    const disabledSubscribeButton = isNextPricing // 当前套餐是next套餐
                      || currentSubscription.version >= 3 && isActivePricing && !activePrice.has_discount_code // 当前版本为3且价格为当前价格
                      || isFreePricing && isActivePricing // 当前套餐为免费套餐

                    return <div
                      className={styles.valueCol}
                      key={plan.name}
                    >
                      <BlockStack
                        gap="300"
                      >
                        <Text as="span" variant="headingMd">{plan.name}</Text>
                        {
                          disabledSubscribeButton
                            ? <Button
                              size="large"
                              fullWidth
                              disabled
                            >{isActivePricing ? t("account:CurrentPlan") : t("account:NextPlan")}</Button>
                            : <Button
                              size="large"
                              fullWidth
                              variant={!isFreePricing ? "primary" : undefined}
                              disabled={loading || disabledSubscription}
                              onClick={() => handlePlanChoose(plan.level, plan.name, activePrice)}
                              loading={chosen === plan.level}
                            >
                              {
                                // 当前套餐则显示CurrentPlan
                                activePrice.trial_days
                                  ? t("common:StartTrialDaysFree", { trialDays: activePrice.trial_days })
                                  : t("account:ChooseThisPlan")
                              }
                            </Button>
                        }
                      </BlockStack>
                    </div>
                  })
                }
              </div>

              {
                ComparisonData.map((group, index) => <>
                  <div className={styles.groupTitle}>
                    <Text as="span" variant="bodyMd" fontWeight="medium">{group.title}</Text>
                  </div>
                  {
                    group.list.map((row, rowIndex) =>
                      <div
                        className={classnames(
                          styles.tableCardRow,
                          (index === ComparisonData.length - 1) && (rowIndex === group.list.length - 1) ? styles.lastRow : "",
                        )}
                        key={rowIndex}
                      >
                        {
                          row.map((item, itemIndex) => itemIndex === 0
                            ? Array.isArray(item)
                            // 数组时，说明有tooltip描述
                              ? <div className={styles.nameCol}>
                                <Tooltip content={item[1]}>
                                  <div className={styles.bottomBorder}>{item[0]}</div>
                                </Tooltip>
                              </div>
                              : <div className={styles.nameCol}>{item}</div>
                            : <div
                              className={classNames(styles.valueCol, styles.valueContent)}
                              key={itemIndex}
                            >
                              {item ? <div className={styles.successColor}><Icon source={CheckIcon} /></div> : "-"}
                            </div>)
                        }
                      </div>,
                    )}
                </>,
                )}
            </div>
          </div>
        </Card>
      }
    </div>

    {/* 降级提醒Modal部分  */}
    <DowngradeModal
      show={showDowngradeModal}
      onClose={() => setShowDowngradeModal(false)}
      confirm={handleDowngradeAlertModalConfirm}
      currentSubscription={currentSubscription}
      tempPlanInfo={tempPlanInfo}
      currentPlan={plans.find(plan => plan.level === currentSubscription.level)}
    />

    {/* Bundle套餐切换提示弹窗*/}
    <Modal
      title={t("account:ChangePlanReminder")}
      open={showBundleChangePlanReminder}
      onClose={() => setShowBundleChangePlanReminder(false)}
      primaryAction={{
        content: t("account:ChangePlan"),
        onAction: () => navigator("/integration?selected_tab=9"),
      }}
      secondaryActions={[
        { content: t("common:Close"), onAction: () => setShowBundleChangePlanReminder(false) },
      ]}
    >
      <Modal.Section>
        {t("account:BundleChangePanReminder")}
      </Modal.Section>
    </Modal>
  </>
}

export default PlanList
