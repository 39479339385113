import { Observer } from "mobx-react"
import React from "react"

import appApi from "@/api/app"
import { MarketingPicture } from "@/component/ImageBanner/MarketingPicture"
import stores from "@/stores"

const MarketingChangePlan = () => {
  const { userInfoSore } = stores
  
  return <Observer>{() => {
    const { banner_discount_v2024_04 } = userInfoSore.data
    
    return <MarketingPicture
      show
      src={banner_discount_v2024_04.main_banner_url}
      onClose={() => {
        appApi.dispatchMarketingBanner("dashboard")
          .then(({ data }) => {
            userInfoSore.setDataSingle("banner_discount_v2024_04", {
              ...banner_discount_v2024_04,
              ...data.banner_discount_v2024_04,
            })
          })
      }}
      to={"/account"}
    />
  }}</Observer>
}

export default MarketingChangePlan
