import { SelectOption } from "@shopify/polaris/build/ts/latest/src/components/Select/Select"
import i18n from "i18next"
import { chunk, cloneDeep, isArray, keys, remove, set } from "lodash-es"
import { action, computed, makeObservable, observable } from "mobx"

import { ITrackingPage } from "@/models/ITrackingPage"
import {
  AdditionalTextSetting,
  AsbodList,
  AutoTranslate,
  BrandingConfig,
  Child,
  CustomCSSAndHTML,
  CustomOrderInput,
  CustomOrderStatus,
  DateAndTimeFormat,
  DisplayOption,
  EstimatedDeliveryTime,
  ITrackingPageOptions,
  PageTranslations,
  PostPurchaseEDD,
  ProRecommendAndUpsell,
  SEOOptimization,
  TrackingPageUrl,
  TranslateTrackingDetailedInfo,
  Translations,
  TranslationsConfig,
} from "@/models/ITrackingPageOptions"
import { defaultEDDConfig } from "@/stores/tracking_page/defaultConfig"

export default class TrackingPageStore implements ITrackingPage, ITrackingPageOptions {
  @observable public loadedDataTime: number = 0

  @observable public displayOption: DisplayOption | undefined
  @observable public pageTranslations: PageTranslations | undefined
  @observable public autoTranslate: AutoTranslate | undefined
  @observable public pageTranslationsD: PageTranslations | undefined
  @observable public customOrderStatus: CustomOrderStatus | undefined
  @observable public customOrderInput: CustomOrderInput | undefined
  @observable public estimatedDeliveryTime: EstimatedDeliveryTime | undefined // 旧版EDD
  @observable public post_purchase_edd: PostPurchaseEDD = defaultEDDConfig // 新版EDD
  @observable public proRecommendAndUpsell: ProRecommendAndUpsell | undefined
  @observable public additionalTextSetting: AdditionalTextSetting | undefined
  @observable public seoOptimization: SEOOptimization | undefined
  @observable public dateAndTimeFormat: DateAndTimeFormat | undefined
  @observable public translateTrackingDetailedInfo: TranslateTrackingDetailedInfo[] | undefined
  @observable public customCssAndHtml: CustomCSSAndHTML | undefined
  @observable public brandingConfig: BrandingConfig | undefined
  @observable public productCollection: any | undefined
  @observable public trackingPageUrl: TrackingPageUrl | undefined

  @observable public translations: Translations | undefined
  @observable public translations_config: TranslationsConfig | undefined

  // 推荐商品选择, 显示未报错
  @observable public recommendationSelectCollectionError: boolean = false

  constructor() {
    makeObservable(this)
  }

  @computed
  get options(): ITrackingPageOptions {
    const data: ITrackingPageOptions = {
      additionalTextSetting: undefined,
      autoTranslate: undefined,
      brandingConfig: undefined,
      customCssAndHtml: undefined,
      customOrderInput: undefined,
      customOrderStatus: undefined,
      dateAndTimeFormat: undefined,
      displayOption: undefined,
      estimatedDeliveryTime: undefined,
      pageTranslations: undefined,
      pageTranslationsD: undefined,
      proRecommendAndUpsell: undefined,
      productCollection: undefined,
      seoOptimization: undefined,
      translateTrackingDetailedInfo: undefined,
      trackingPageUrl: undefined,
      translations_config: undefined,
      translations: undefined,
      post_purchase_edd: undefined,
    }

    let currentKeys: (keyof ITrackingPageOptions)
    // @ts-ignore
    // .... 😶这里直接变量this，会导致后面加变量都得排除一下，不然就触发保存条。

    for (currentKeys in this) {
      // @ts-ignore
      if (currentKeys === "loadedDataTime" || currentKeys === "recommendationSelectCollectionError") continue
      // @ts-ignore
      data[currentKeys] = cloneDeep(this[currentKeys])
    }

    return data
  }

  @computed
  get pageTranslationsChunk(): string[][] {
    return chunk(keys(this.pageTranslations), 2)
  }

  @computed
  get trackPageUrl(): string {
    const { subpathPrefix, subpath } = this.trackingPageUrl || {}

    return `/${subpathPrefix || "apps"}/${subpath || "parcelpanel"}`
  }

  @computed
  get publishedLanguagesOptions(): SelectOption[] | undefined {
    return this.translations_config?.locales.map(({ value, name }) => {
      const label = this.translations_config?.primary_locale === value
        ? `${name} (${i18n.t("tracking_page:PublishedLanguages.defaultText")})`
        : name

      return {
        value,
        label,
      } as SelectOption
    })
  }

  @action.bound
  public setProductCollection(data: any): any {
    this.productCollection = data
  }

  @action.bound
  public setBaseOptions(data: Partial<ITrackingPageOptions>): boolean {
    let currentKeys: (keyof ITrackingPageOptions)

    for (currentKeys in data) {
      if (currentKeys === "translateTrackingDetailedInfo" && (data[currentKeys]?.length || 0) === 0) {
        data[currentKeys] = [{ after: "", before: "" }]
      }

      // 换行符号导致 进行处理
      if (currentKeys === "customCssAndHtml" && data[currentKeys]) {
        // @ts-ignore
        const { htmlBottomCode } = data[currentKeys]
        // @ts-ignore

        data[currentKeys].htmlBottomCode = htmlBottomCode.replace(/\r\n/g, "\n")
      }

      // @ts-ignore
      this[currentKeys] = data[currentKeys]
    }
    return true
  }

  @action.bound
  public setOption<T>(type: keyof ITrackingPageOptions, key: keyof T, val: any): boolean {
    // @ts-ignore
    this[type][key] = val
    return true
  }

  @action.bound
  public setOptions<T>(type: keyof ITrackingPageOptions, val: T): boolean {
    if (isArray(val)) {
      // @ts-ignore
      this[type] = val
    } else {
      // @ts-ignore
      this[type] = { ...this[type], ...val }
    }

    return true
  }

  @action.bound
  public setEDDTargetConfig<T>(path: string, config: T): void {
    set(this.post_purchase_edd, path, config)
  }

  @action.bound
  public setCustomOrderStatus(customOrderStatusIndex: keyof CustomOrderStatus, value: Child[]): boolean {
    this.customOrderStatus![customOrderStatusIndex].children = value
    return true
  }

  @action.bound
  public editASBODItem(index: number, item: AsbodList): boolean {
    const asbodLists = cloneDeep(this.estimatedDeliveryTime!.ASBOD.list)

    asbodLists[index] = item

    this.estimatedDeliveryTime!.ASBOD.list = asbodLists
    return true
  }

  @action.bound
  public pushASBODItem(item: AsbodList): boolean {
    const asbodLists = cloneDeep(this.estimatedDeliveryTime!.ASBOD.list)

    asbodLists.push(item)
    this.estimatedDeliveryTime!.ASBOD.list = asbodLists
    return true
  }

  @action.bound
  public removeASBODItem(index: number): boolean {
    const asbodLists = cloneDeep(this.estimatedDeliveryTime!.ASBOD.list)

    remove(asbodLists, (item, _index) => {
      return _index === index
    })
    this.estimatedDeliveryTime!.ASBOD.list = asbodLists
    return true
  }

  @action.bound
  public removeAllASBOD(index: boolean): boolean {
    if (!index) {
      const a = this.estimatedDeliveryTime?.ASBOD.list.filter(o => o.shipping_to !== "0") || []

      this.estimatedDeliveryTime!.ASBOD.list = a
      if (a.length < 1) {
        this.estimatedDeliveryTime!.ASBOD.enabled = false
      }
    }
    return true
  }

  @action.bound
  public filterProductRecommendationsOptions() {
    const options = this.proRecommendAndUpsell?.productRecommendations

    if (!options) {
      return
    }

    // if (!options.adv_coll_enabled || !options.collect) {
    //   /* 复选框 与 下拉框 必须同时选中，否则还原为未选择 */
    //   options.adv_coll_enabled = false
    //   options.collect = 0
    // }

    return cloneDeep(options)
  }

  @action.bound
  public addAnotherHandle(item: TranslateTrackingDetailedInfo): boolean {

    const asbodLists = cloneDeep(this.translateTrackingDetailedInfo) || []

    asbodLists.push(item)
    this.translateTrackingDetailedInfo = asbodLists
    return true
  }

  @action.bound
  public removeAnotherHandle(): any {

    const a = this.translateTrackingDetailedInfo?.filter(o => o.before !== "") || []

    if (a.length < 1) {
      a.push({
        after: "",
        before: "",
      })
    }
    this.translateTrackingDetailedInfo = a
    return a
  }

  @action.bound
  public setLoadedDataTime(time: number): void {
    this.loadedDataTime = time
  }
  @action.bound
  public setRecommendationSelectCollectionError(val: boolean): void {
    this.recommendationSelectCollectionError = val
  }
}
