import { Text } from "@shopify/polaris"
import classNames from "classnames"
import { Observer } from "mobx-react"
import React from "react"

import settingsApi from "@/api/settings"
import { MarketingPicture } from "@/component/ImageBanner/MarketingPicture"
import stores from "@/stores"

import styles from "./index.module.scss"
import useCountdown from "./useCountdown"

const ChannelwillBundle = () => {
  const { userInfoSore } = stores
  const targetDate = new Date("2024-12-31T23:59:59") // 设置目标日期
  const { days, hours, minutes, seconds } = useCountdown(1735660800000)

  // useEffect(() => {
  //   settingsApi.patchBanner(28692, true)
  // }, [])

  return <Observer>{() => {
    const { banner_data } = userInfoSore.data
    const banner = banner_data.find(item => item.id === 28692)

    if (!banner) return null
    
    return <div className={"relative"}>
      <MarketingPicture
        show
        src={banner.image_url}
        onClose={() => {
          settingsApi.patchBanner(28692, false).then(({ data: { code } }) => {
            code === 200 && stores.userInfoSore.setBannerClose(28692)
          })
        }}
        to={"/integration?selected_tab=9"}
      />

      <div className={classNames(styles.countdownWrapper, "flex gap-1 items-center")}>
        {String(days).split("")
          .map((item, index) => <div className={styles.countdownBlock} key={index}>{item}</div>)}

        <div className={"mx-1"}>
          <Text as={"p"} variant={"bodyMd"}>DAYS</Text>
        </div>

        {String(hours).split("")
          .map((item, index) => <div className={styles.countdownBlock} key={index}>{item}</div>)}

        <Text as={"p"} variant={"bodyMd"}>:</Text>

        {String(minutes).split("")
          .map((item, index) => <div className={styles.countdownBlock} key={index}>{item}</div>)}

        <Text as={"p"} variant={"bodyMd"}>:</Text>

        {String(seconds).split("")
          .map((item, index) => <div className={styles.countdownBlock} key={index}>{item}</div>)}

      </div>
    </div>
  }}
  </Observer>
}

export default ChannelwillBundle
