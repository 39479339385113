import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Observer } from "mobx-react"
import React from "react"

import ChannelwillBundle from "@/pages/Dashboard/components/MarketingCarousel/ChannelwillBundle"
import MarketingChangePlan from "@/pages/Dashboard/components/MarketingCarousel/MarketingChangePlan"
import stores from "@/stores"

import Slider from "react-slick";

import styles from "./index.module.scss"

const MarketingCarousel = () => {
  const { userInfoSore } = stores

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    arrows: false,
    autoplaySpeed: 3000,
    cssEase: "linear",
    touchMove: false,
  };

  return <Observer>{() => {
    const { banner_data, banner_discount_v2024_04 } = userInfoSore.data
    const banner = banner_data.find(item => item.id === 28692)
    const showChannelwillBundle = banner && banner.display
    const showMarketingChangePlan = banner_discount_v2024_04.dashboard

    if (!showChannelwillBundle && !showMarketingChangePlan) return null

    return <div className={styles.MarketingCarousel}>
      {
        showChannelwillBundle && showMarketingChangePlan
          ? <div className="slider-container">
            <Slider {...settings}>
              <ChannelwillBundle/>
              <MarketingChangePlan/>
            </Slider>
          </div>
          : <>
            {showChannelwillBundle && <ChannelwillBundle/>}
            {showMarketingChangePlan && <MarketingChangePlan/>}
          </>
      }
    </div>
  }}</Observer>

}

export default MarketingCarousel
