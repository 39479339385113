import { useScript } from "@channelwill/hooks"
import {
  AppLink,
  Fullscreen,
  NavigationMenu,
  Redirect,
} from "@shopify/app-bridge/actions"
import { Action as RedirectAction } from "@shopify/app-bridge/actions/Navigation/Redirect"
import { isMobile, isShopifyEmbedded } from "@shopify/app-bridge-utils"
import { FooterHelp, Tabs } from "@shopify/polaris"
import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"
import { get, startsWith } from "lodash-es"
import { Observer } from "mobx-react"
import React, { Suspense, useEffect, useLayoutEffect } from "react"
import { getI18n, useTranslation } from "react-i18next"
import { NavigateFunction } from "react-router"
import { Navigate, Route, Routes, useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { IntercomProps, useIntercom } from "react-use-intercom"

import { postRouteMessage } from "@/admin-bridge/admin-bridge"
import { APICode } from "@/api/APICode"
import appApi from "@/api/app"
import dashboard from "@/api/dashboard"
import trackingPageAPI from "@/api/tracking-page"
import { FeedbackModal, Footer, FullScreenLoading, MarketingPopup } from "@/component"
import { SkeletonSettings } from "@/component/SkeletonSettings"
import SkeletonTrackingPageAppearance from "@/component/SkeletonTrackingPage/SkeletonTrackingPage"
import useErrorHandler from "@/hooks/useVersionError"
import { differenceLangMap } from "@/i18n/resource"
import { Data as UserInfoResponseData, IUserInfo } from "@/models/IUserInfo"
import { Account, OrdersUpdateStatus } from "@/pages"
import SkeletonAnalytics from "@/pages/Analytics/SkeletonAnalytics"
import SkeletonOrders from "@/pages/Orders/components/SkeletonOrders/SkeletonOrders"
import { ShipmentInfo } from "@/pages/Orders/OrderInfo"
import OrdersDetail from "@/pages/Orders/OrdersDetail"
import SkeletonTrackingPage from "@/pages/TrackingPage/SkeletonTrackingPage/SkeletonTrackingPage"
import TrackingPage from "@/pages/TrackingPage/TrackingPage"
import { orderPost, pathnameHandle, setCurrentShopifyAppPath } from "@/router/history"
import stores from "@/stores"
import AppBridge, { getAppBase, getAppLink } from "@/utils/appBridge"
import { setBetaFeatures } from "@/utils/BetaFeature"
import { isFromAdmin } from "@/utils/is-from-admin"
import { LanguageUtils } from "@/utils/languages"
import { IListenerNameEnum } from "@/utils/languages.d"
import UrlUtils from "@/utils/UrlUtils"

import DynamicLoadError from "./component/DynamicLoadError/DynamicLoadError"
import SkeletonIntegration from "./component/SkeletonIntegration/SkeletonIntegration"
import AddOns from "./pages/AddOns/index"
import Dashboard from "./pages/Dashboard/index"

dayjs.extend(utc)
dayjs.extend(timezone)

const Orders = React.lazy(() => import("@/pages/Orders/Orders"))
const UpgradeRequired = React.lazy(() => import("@/component/UpgradeRequired/UpgradeRequired"))

const Integration = React.lazy(() => import("@/pages/Integration/Integration"))

// Settings
const Settings = React.lazy(() => import("@/pages/Settings/Settings"))
const EmailEditor = React.lazy(() => import("@/pages/Settings/EmailEdit"))

const Billing = React.lazy(() => import("@/pages/Billing"))

// Tracking Page
const DisplayOptions = React.lazy(() => import("@/pages/TrackingPage/DisplayOptions/DisplayOptions"))
const TrackingPageTranslations = React.lazy(() => import("@/pages/TrackingPage/TrackingPageTranslations/TrackingPageTranslations"))
const CustomOrderStatus = React.lazy(() => import("@/pages/TrackingPage/CustomOrderStatus/CustomOrderStatus"))
const EstimatedDeliveryTime = React.lazy(() => import("@/pages/TrackingPage/EstimatedDeliveryTime/EstimatedDeliveryTime"))
const ProductRecommendationUpsell = React.lazy(() => import("@/pages/TrackingPage/ProductRecommendationUpsell/ProductRecommendationUpsell"))
const SEOOptimization = React.lazy(() => import("@/pages/TrackingPage/SEOOptimization/SEOOptimization"))
const TrackingPageURL = React.lazy(() => import("@/pages/TrackingPage/TrackingPageURL/TrackingPageURL"))
const CustomCSSAndHTML = React.lazy(() => import("@/pages/TrackingPage/CustomCSSAndHTML/CustomCSSAndHTML"))

// Analytics统计
const AnalyticsShipment = React.lazy(() => import("@/pages/Analytics/shipment"))
const AnalyticsTransitTime = React.lazy(() => import("@/pages/Analytics/transitTime"))
const AnalyticsTrackingPage = React.lazy(() => import("@/pages/Analytics/trackingPage"))
const AnalyticsShippingNotifications = React.lazy(() => import("@/pages/Analytics/shippingNotifications"))

// DeliveryGuarantee保险app页面
import env from "@/config/env"
import SkeletonDelivery from "@/pages/DeliveryGuarantee/components/SkeletonDelivery"
import SkeletonTable from "@/pages/DeliveryGuarantee/components/SkeletonTable"
import DeliveryGuarantee from "@/pages/DeliveryGuarantee/DeliveryGuarantee"
const DGSetting = React.lazy(() => import("@/pages/DeliveryGuarantee/DGSetting/DGSetting"))
const Covers = React.lazy(() => import("@/pages/DeliveryGuarantee/Covers/Covers"))
const Claims = React.lazy(() => import("@/pages/DeliveryGuarantee/Claims/Claims"))
const Transactions = React.lazy(() => import("@/pages/DeliveryGuarantee/Transactions/Transactions"))

const App = () => {
  const { boot } = useIntercom()
  const { pathname } = useLocation()

  const navigate = useNavigate()
  const { t } = useTranslation("common")
  const MenuIl8n = useTranslation("menu").t

  LanguageUtils.addListener(IListenerNameEnum.UpgradeBtnTextListener, () => {
    stores.userInfoSore.setUpgradeBtnText(stores.userInfoSore.data, t)
  })

  // 判断是否在 user-guide 页面
  const userGuideStr = "/user-guide"
  const isUserGuidePage = pathname.substring(0, userGuideStr.length) === userGuideStr
  const isOrder = pathname.substring(0, "/order".length) === "/order"

  const [searchParams] = useSearchParams()

  const shop = searchParams.get("shop") || ""
  const embedded = searchParams.get("embedded") || ""
  let authToken = searchParams.get("authToken") || ""

  if (authToken.length === 0) {
    try {
      const parcelpanelToken = window.sessionStorage.getItem("parcelpanel-token")
      const isValidToken = parcelpanelToken?.split(".").length === 3

      if (isValidToken) {
        authToken = parcelpanelToken
      }
    } catch (e) {
      console.error(e)
    }
  }


  const requestAuthLink = async () => {
    const { data: { code, data: { oauth_link } } } = (await appApi.shopifyAuthLink(shop)) || {}

    if (code === 200) {
      window.location.href = oauth_link
    }
  }
  // 引入shopify货币转换js

  useScript("https://cdn.shopify.com/s/javascripts/currencies.js?time=" + new Date().getTime(), "currencies")

  // useErrorHandler()

  useEffect(() => {
    const { userInfoSore } = stores

    if (import.meta.env.MODE !== "development" && embedded !== "1" && !isFromAdmin(authToken)) {
      console.log("满足条件，准备请求授权安装链接【embedded = 1】")
      requestAuthLink()
      return
    }

    initAPPBridge(searchParams, navigate)

    appApi.userInfo()
      .then(({ data: response }: { data: IUserInfo }) => {
        if (response.code === APICode.OK) {

          const { link, update_scope } = get(response.data, "oauth") || { link: "", update_scope: false }

          if (update_scope && AppBridge.exist()) {
            AppBridge.redirect.dispatch(RedirectAction.REMOTE, { url: link })
            return
          }

          // 设置默认时区
          response.data?.timezone && dayjs.tz.setDefault(response.data.timezone)

          userInfoSore.setData(response.data)
          userInfoSore.setIsLoadData(true)

          if (response.data) {
            const { data } = response

            if (data?.beta_features) {
              setBetaFeatures(data.beta_features)
            }

            if (data?.inject_js) {
              window?.parcelpanelInjectScript(`${env.base_api_url}${data.inject_js}`)
            }

            initIntercom(data, boot)

            stores.commonStore.getBanner20493()
          }

          // survey
          try {
            appApi.survey()
              .then(({ data, status }) => {
                if (status !== 200 || !data.survey.show) {
                  return
                }

                const { survey } = data

                // @ts-ignore
                window?.surveylite?.initSurvey({
                  "container": "#sv-builtin-container",
                  "clientId": survey.client_id,
                  "externalUserId": survey.external_user_id,
                  "sid": survey.sid,
                  "channelId": survey.channel_id,
                  "width": survey.width,
                  "parameters": survey.parameters,

                  // "departmentCode": "",
                  // "externalCompanyId": "",
                  "env": "https://g.xmplus.cn",
                  "animation": true,

                  // 自定义回调函数注册
                  "onClose": () => {
                    appApi.surveyMarkClose()
                  },

                  "onSubmit": () => {
                    appApi.surveyMarkSubmit()
                  },
                  // "onLoad": handleLoad,
                  // "onFailed": onFailed,
                })

              })
          } catch (e) {
            console.error(e)
          }
        }
      })
      .finally(() => {
        window?.hideLoadingState()
      })

  }, [])

  // 更新选中的shopify左侧菜单
  const updateMenuActive = () => {
    const { globalMenuStore } = stores

    if (pathname === "/") {
      AppBridge.navigationMenu.set({ active: undefined })
      return
    }
    const targetTab = globalMenuStore.tabs.find(item => {
      const PATHURL = location.pathname.replace(getAppBase(), "")
        .replaceAll("/", "")

      let target

      if (PATHURL === "account") {
        target = "billing"
      } else if (PATHURL.includes("analytics")) {
        target = "analytics"
      } else if (PATHURL.includes("tracking-page")) {
        target = "tracking-page"
      } else {
        target = PATHURL
      }

      return item.id.replaceAll("/", "") === target
    })

    if (targetTab) {
      const targetLink = AppBridge.navigationMenu.children.find(nav => nav?.destination === getAppLink(targetTab?.id))

      targetLink && AppBridge.navigationMenu.set({ active: targetLink })
    }
  }

  useEffect(() => {
    const { globalMenuStore } = stores

    if (pathname !== "/" && globalMenuStore.selectedTabIndex === 0) {
      // let selectedTabIndex = _.map(globalMenuStore.tabs, 'id').indexOf(pathname);
      const selectedTabIndex = globalMenuStore.tabs.findIndex(value => "/" === value.id ? value.id === pathname : pathname.startsWith(value.id))

      0 <= selectedTabIndex && globalMenuStore.selectTab(selectedTabIndex)
    }

    if (!window.isFromAdmin && isShopifyEmbedded()) {
      // 过滤不生成shopify左侧菜单栏项
      const filterMenu = (menuItem: { id: string }) => {
        return menuItem.id !== "/" && menuItem.id !== "/account" && menuItem.id !== "/get-help"
      }
      const menuItems = globalMenuStore.tabs.filter(filterMenu)
        .map(item => {
          return AppLink.create(AppBridge.app, {
            label: MenuIl8n(item.i18nKey),
            destination: getAppLink(item.id),
          })
        })
      const navigationMenu = NavigationMenu.create(AppBridge.app, {
        items: menuItems,
      })

      AppBridge.setNavigationMenu(navigationMenu)
      updateMenuActive()
    }

    const locale = differenceLangMap[getI18n().language]
      ? differenceLangMap[getI18n().language]
      : getI18n()
        .language
        .toLocaleUpperCase()

    trackingPageAPI.fetchCountriesAndTimeZones({ locale })
      .then(({ data }) => {
        stores.commonStore.setCountries(data.countries)
        stores.commonStore.setTimeZones(data.timeZones)
      })
  }, [getI18n().language])

  useEffect(() => {
    // 如果首页, 第一次加载quickSetupData 用户引导步骤信息
    if (pathname === "/" && !stores.userInfoSore.quickSetupInitialized) {
      dashboard.getSetupGuide()
        .then(({ data }) => {
          stores.userInfoSore.setQuickSetupData(data.setup_guide)
        })
    }

    if (window.isFromAdmin !== true && isShopifyEmbedded()) {
      if (startsWith(pathname, "/settings/email-edit")) {
        AppBridge.dispatchFullscreen(Fullscreen.Action.ENTER)
      } else {
        AppBridge.dispatchFullscreen(Fullscreen.Action.EXIT)
      }

      updateMenuActive()
    }
  }, [pathname])

  useLayoutEffect(() => {
    const { globalMenuStore } = stores

    pathnameHandle(pathname)

    const selectedTabIndex = globalMenuStore.tabs.findIndex(value => "/" === value.id ? value.id === pathname : pathname.startsWith(value.id))

    0 <= selectedTabIndex && globalMenuStore.selectTab(selectedTabIndex)

    orderPost(pathname, navigate, searchParams)

    // 跳转后，返回页面顶部
    window.scrollTo(0, 0)

    // 通知管理后台，路径改变
    postRouteMessage(pathname, searchParams.toString())

  }, [pathname])

  // 是否显示全局菜单栏
  const showTopTab = !isUserGuidePage
    && !startsWith(pathname, "/orders/update-status")
    && !startsWith(pathname, "/get-help")

  const isEmailEditPage = startsWith(pathname, "/settings/email-edit")
  const isAnalyticsPage = startsWith(pathname, "/analytics")
  const isBillingPage = startsWith(pathname, "/billing")

  // 忽略是否加载完用户数据
  const ignoreIsLoadedUserData = startsWith(pathname, "/orders/update-status")

  const hideUpgradeRequired = pathname === "/account" || pathname === "/billing" // || true

  return <Observer>{() => (
    <>
      {
        !hideUpgradeRequired && stores.userInfoSore.isLoadData &&
        <DynamicLoadError>
          <Suspense fallback={null}>
            <UpgradeRequired
              active={stores.upgradeStore.upgradeModalActive}
              handleChange={stores.upgradeStore.handleCloseModal}
              btnText={stores.upgradeStore.btnText}
            />
          </Suspense>
        </DynamicLoadError>
      }

      {/* todo 改了字体大小导致的tab项会换行？ */}

      {stores.userInfoSore.isLoadData && showTopTab && !isEmailEditPage && <div id="global-menu">
        <div className="flex-1">
          <HeaderTab />
        </div>
      </div>}

      {/* 只有当用户基础数据加载完成了，才去加载相关页面*/}
      {(stores.userInfoSore.isLoadData || ignoreIsLoadedUserData) ? (
        <Routes>
          <Route path="/">
            <Route path="" element={<Dashboard />} />
          </Route>
          <Route path="/tracking-page" element={<TrackingPage />}>

            <Route path="" element={(
              <DynamicLoadError>
                <Suspense fallback={<SkeletonTrackingPageAppearance />}>
                  <DisplayOptions trackingPageStore={stores.trackingPageStore} />
                </Suspense>
              </DynamicLoadError>
            )}
            />
            <Route path="appearance" element={(
              <DynamicLoadError>
                <Suspense fallback={<SkeletonTrackingPage />}>
                  <DisplayOptions trackingPageStore={stores.trackingPageStore} />
                </Suspense>
              </DynamicLoadError>
            )}
            />
            <Route path="languages" element={(
              <DynamicLoadError>
                <Suspense fallback={<SkeletonTrackingPage />}>
                  <TrackingPageTranslations />
                </Suspense>
              </DynamicLoadError>
            )}
            />
            <Route path="customShipmentStatus" element={(
              <DynamicLoadError>
                <Suspense fallback={<SkeletonTrackingPage />}>
                  <CustomOrderStatus trackingPageStore={stores.trackingPageStore} />
                </Suspense>
              </DynamicLoadError>
            )}
            />
            <Route path="estimatedDeliveryTime" element={(
              <DynamicLoadError>
                <Suspense fallback={<SkeletonTrackingPage />}>
                  <EstimatedDeliveryTime />
                </Suspense>
              </DynamicLoadError>
            )}
            />
            <Route path="productRecommendation" element={(
              <DynamicLoadError>
                <Suspense fallback={<SkeletonTrackingPage />}>
                  <ProductRecommendationUpsell trackingPageStore={stores.trackingPageStore} />
                </Suspense>
              </DynamicLoadError>
            )}
            />
            {/* <Route path="additionalText" element={<AdditionalTextSetting/>}/>*/}
            <Route path="seoOptimization" element={(
              <DynamicLoadError>
                <Suspense fallback={<SkeletonTrackingPage />}>
                  <SEOOptimization />
                </Suspense>
              </DynamicLoadError>
            )}
            />
            <Route path="trackingPageUrl" element={(
              <DynamicLoadError>
                <Suspense fallback={<SkeletonTrackingPage />}>
                  <TrackingPageURL />
                </Suspense>
              </DynamicLoadError>
            )}
            />
            <Route path="CSS&HTML" element={(
              <DynamicLoadError>
                <Suspense fallback={<SkeletonTrackingPage />}>
                  <CustomCSSAndHTML />
                </Suspense>
              </DynamicLoadError>
            )}
            />
          </Route>
          <Route path="/orders">
            <Route path="" element={(
              <DynamicLoadError>
                <Suspense fallback={<SkeletonOrders />}>
                  <Orders />
                  <FooterHelp>
                    <Footer pathname={pathname} />
                  </FooterHelp>
                </Suspense>
              </DynamicLoadError>
            )}
            />
            <Route path="update-status" element={<OrdersUpdateStatus />} />
            <Route path="detail" element={<OrdersDetail />} />
            <Route path={"shipments/:shipmentID"} element={<ShipmentInfo />} />
          </Route>
          <Route path="/settings">
            <Route path="" element={(
              <DynamicLoadError>
                <Suspense fallback={<SkeletonSettings />}>
                  <Settings />
                </Suspense>
              </DynamicLoadError>
            )}
            />
            <Route path="email-edit/:orderStatus" element={(
              <DynamicLoadError>
                <Suspense fallback={<FullScreenLoading />}>
                  <EmailEditor />
                </Suspense>
              </DynamicLoadError>
            )}
            />
          </Route>

          <Route path="/billing" element={<DynamicLoadError>
            <Suspense fallback={null}>
              <Billing />
              <FooterHelp>
                <Footer pathname={pathname} />
              </FooterHelp>
            </Suspense>
          </DynamicLoadError>}
          />
          <Route path="/account" element={<Account />} />
          <Route path="/integration" element={
            <DynamicLoadError>
              <Suspense fallback={<SkeletonIntegration />}>
                <Integration />
              </Suspense>
            </DynamicLoadError>
          }
          />
          <Route path="/analytics" element={
            <DynamicLoadError>
              <Suspense fallback={<SkeletonAnalytics />}>
                <AnalyticsShipment />
                <FooterHelp>
                  <Footer pathname={pathname} />
                </FooterHelp>
              </Suspense>
            </DynamicLoadError>
          }
          />
          <Route path="/analytics/Shipment" element={
            <DynamicLoadError>
              <Suspense fallback={<SkeletonAnalytics />}>
                <AnalyticsShipment />
                <FooterHelp>
                  <Footer pathname={pathname} />
                </FooterHelp>
              </Suspense>
            </DynamicLoadError>
          }
          />
          <Route path="/analytics/transit-time" element={
            <DynamicLoadError>
              <Suspense fallback={<SkeletonAnalytics />}>
                <AnalyticsTransitTime />
                <FooterHelp>
                  <Footer pathname={pathname} />
                </FooterHelp>
              </Suspense>
            </DynamicLoadError>
          }
          />
          <Route path="/analytics/tracking-page" element={
            <DynamicLoadError>
              <Suspense fallback={<SkeletonAnalytics />}>
                <AnalyticsTrackingPage />
                <FooterHelp>
                  <Footer pathname={pathname} />
                </FooterHelp>
              </Suspense>
            </DynamicLoadError>
          }
          />
          <Route path="/analytics/shipping-notifications" element={
            <DynamicLoadError>
              <Suspense fallback={<SkeletonAnalytics />}>
                <AnalyticsShippingNotifications />
                <FooterHelp>
                  <Footer pathname={pathname} />
                </FooterHelp>
              </Suspense>
            </DynamicLoadError>
          }
          />

          <Route path="/protection/" element={<DeliveryGuarantee />}>
            <Route path="" element={(
              <DynamicLoadError>
                <Suspense fallback={<SkeletonDelivery />}>
                  <DGSetting />
                </Suspense>
              </DynamicLoadError>)}
            />
            <Route path="general" element={(
              <DynamicLoadError>
                <Suspense fallback={<SkeletonDelivery />}>
                  <DGSetting />
                </Suspense>
              </DynamicLoadError>)}
            />
            <Route path="covers" element={(
              <DynamicLoadError>
                <Suspense fallback={<SkeletonTable />}>
                  <Covers />
                </Suspense>
              </DynamicLoadError>)}
            />
            <Route path="claims" element={(
              <DynamicLoadError>
                <Suspense fallback={<SkeletonTable />}>
                  <Claims />
                </Suspense>
              </DynamicLoadError>)}
            />
            <Route path="bills" element={(
              <DynamicLoadError>
                <Suspense fallback={<SkeletonTable />}>
                  <Transactions />
                </Suspense>
              </DynamicLoadError>
            )}
            />
          </Route>

          <Route path="/add-ons" element={<AddOns />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      ) : (
        <FullScreenLoading />
      )}

      {stores.userInfoSore.isLoadData && !isEmailEditPage && !isUserGuidePage && !isOrder && !isAnalyticsPage && !isBillingPage &&
      <FooterHelp>
        <Footer pathname={pathname} />
      </FooterHelp>}

      {/* 广告弹框 */}
      {!window.isFromAdmin && stores.userInfoSore.isLoadData && !stores.userInfoSore.data.showNewUserGuide &&
      <MarketingPopup />}

      <FeedbackModal
        rate={stores.Post.rate}
        show={stores.Post.showCommentModal && stores.Post.rate < 5}
        onClose={() => stores.Post.setShowCommentModal(false)}
      />
    </>
  )}</Observer>
}

function HeaderTab() {
  const navigate = useNavigate()

  // 难道不能这解构，要在 Observer内解构？
  const { globalMenuStore } = stores
  const { t } = useTranslation("menu")

  useEffect(() => {
    globalMenuStore.setTabs(globalMenuStore.tabs.map(value => {
      value.content = t(value.i18nKey)
      return value
    }))
  }, [getI18n().language])

  return (
    <Observer>{() => isShopifyEmbedded() && !window.isFromAdmin ? null : (
      <Tabs
        tabs={globalMenuStore.tabs}
        selected={globalMenuStore.selectedTabIndex}
        onSelect={(id) => navigate(globalMenuStore.tabs[id].id || "/")}
      />
    )}</Observer>
  )
}

function initAPPBridge(searchParams: URLSearchParams, navigate: NavigateFunction) {
  // Shopify 重定向过来，携带了这些参数
  const shop = searchParams.get("shop") as string
  const host = searchParams.get("host") as string
  // 从我们后台访问，会携带这个东西
  let authToken = searchParams.get("authToken") || ""

  if (authToken.length === 0) {
    try {
      const parcelpanelToken = window.sessionStorage.getItem("parcelpanel-token")
      const isValidToken = parcelpanelToken?.split(".").length === 3

      if (isValidToken) {
        authToken = parcelpanelToken
      }
    } catch (e) {
      console.error(e)
    }
  }

  // 如果存在这个东西表示是从我们超管后台进来的，不执行跳转
  if (isFromAdmin(authToken)) {
    console.log(`%c-->超管后台环境, isMobile: ${isMobile() ? "true" : "false"} , isShopifyEmbedded: ${isShopifyEmbedded() ? "true" : "false"}`, "background-color:#4B51F1; color: white; padding: 4px 8px;")
    // 添加这个是为了让父页面能够判断出来
    window.isFromAdmin = true
    stores.commonStore.setAuthToken(authToken)
    return
  }

  if (import.meta.env.MODE === "development") {
    console.log("%c开发环境", "background-color:#00a0ac; color: white; padding: 4px 8px;")
    if (!isShopifyEmbedded()){
      return
    }
  }

  if (shop && shop.length > 5 && host && host.length > 5 && isShopifyEmbedded()) {
    if (isMobile()) {
      console.log("%c APP 运行环境：mobile", "background-color:#007557; color: white; padding: 4px 8px;")
    } else {
      console.log("%c APP 运行环境：desktop web browser", "background-color:#007557; color: white; padding: 4px 8px;")
    }
  } else {
    console.log("%c app is not running as an embedded app", "background-color:red; color: white; padding: 4px 8px;")
  }

  AppBridge.set(host)
    .subscribe(Redirect.Action.APP, (data) => {
      const path: string = data?.path ?? getAppLink()

      if (path === "/" || path === getAppLink("/") || startsWith(path, getAppLink("?"))) {
        setCurrentShopifyAppPath("/")
        navigate("/")
        return
      }

      if (startsWith(path, getAppBase())) {
        navigate(setCurrentShopifyAppPath(
          path.replace(getAppBase(), ""),
        ))
      }
    })
}

/** 初始化 Intercom */
function initIntercom(data: UserInfoResponseData, boot: (props?: IntercomProps) => void) {
  try {
    if (UrlUtils.get("hidden_intercom") || import.meta.env.VITE_SHOW_INTERCOM === "false") {
      return
    }

    if (window.ParcelPanel && window.ParcelPanel?.isFromAdmin && window.ParcelPanel.hiddenIntercom) {
      return
    }

    window.parent.window.PPRemoveHiddenIntercomFromUrl?.()
  } catch (e) {
    console.debug(e)
  }

  const rating = data?.rating || 0

  let from_app = "ParcelPanel"
  let user_id = data?.user_id

  try {
    const intercomUidPrefix = import.meta.env.VITE_INTERCOM_UID_PREFIX || ""
    const intercomFromAppPrefix = import.meta.env.VITE_INTERCOM_FROM_APP_PREFIX || ""

    if (intercomUidPrefix.includes("test_")) {
      user_id = `${import.meta.env.VITE_INTERCOM_UID_PREFIX}${user_id}`
    }

    if (intercomFromAppPrefix.includes("Test_")) {
      from_app = `${import.meta.env.VITE_INTERCOM_FROM_APP_PREFIX}${from_app}`
    }
  } catch (e) {
    console.error(e)
  }

  boot({
    customAttributes: {
      "30-day_orders": data.last_30d_count ?? "Unknown",
      from_app: from_app,
      plan: `${data.plan_name || "Unknown"} $${data.plan_price || "0"}`,
      review: rating === 0 ? "no review" : `${rating}-star`,
      review_name: data?.review_name || "no review name",
      shop_name: data.ShopName?.replace("https://", "") ?? "no shopName",
      shopify_store_version: data?.shop_plan_display_name || "Unknown", // Shopify 店铺版本
      protection: data?.protection || "",
    },

    email: data.user_email || "no email",
    name: data.user_name || "no name",
    userId: user_id || "no userId",
  })
}

export default App
