import { useEffect, useState } from "react"

function useCountdown(targetTimestamp: number) {
  const calculateTimeLeft = () => {
    const now = Date.now()
    const distance = targetTimestamp - now

    const formatTime = (time: number) => (time < 10 ? `0${time}` : time)

    const days = Math.floor(distance / (1000 * 60 * 60 * 24))
    const hours = formatTime(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)))
    const minutes = formatTime(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)))
    const seconds = formatTime(Math.floor((distance % (1000 * 60)) / 1000))

    return { days, hours, minutes, seconds, distance }
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)

    return () => clearInterval(timer)
  }, [targetTimestamp])

  return timeLeft
}

export default useCountdown
