import { QuotaReminderType } from "@/models/ITrackingPageOptions"

/**
 * Plan
 */
export interface Plan {
  feature_header?: null | string;
  features_overview: string[];
  /**
   * 套餐周期
   */
  interval: AppPricingInterval;
  /**
   * 套餐介绍
   */
  introduction: string;
  is_recommended?: boolean | null;
  /**
   * 套餐等级，用于套餐分组
   */
  level: number;
  /**
   * 套餐名
   */
  name: string;
  pricing: PlanPricing[];
}

/**
 * 套餐周期
 *
 * AppPricingInterval，计费周期
 */
export enum AppPricingInterval {
  Annual = "ANNUAL",
  Every30_Days = "EVERY_30_DAYS",
}

/**
 * 付款类型 按月付费 | 按年付费
 */
export type PayType = AppPricingInterval

/**
 * Discount
 */
export interface Discount {
  /**
   * 折扣金额
   */
  amount: null | string;
  /**
   * 折扣周期
   */
  limit_intervals: number;
  /**
   * 折扣百分比
   */
  percentage: number | null;
}

export enum DiscountStyleEnum {
  Discount50 = 1
}

export interface priceInterface {
  price_amount: string
  unit_price_amount: string
}

/**
 * PlanPricing
 */
export interface PlanPricing {
  /**
   * 套外额度单价
   */
  additional_quota_price: number;
  discount: null | Discount;
  /**
   * 定价 ID，粒度最精细的
   */
  id: number;

  interval: AppPricingInterval;
  /**
   * 是否为自定义套餐
   */
  is_custom: boolean;
  level: number;
  replacement_behavior: string;
  /**
   * 额度
   */
  quota: number

  /**
   * 试用天数
   */
  trial_days: number;
  /**
   * -1 是降级，0 1 是升级
   */
  comparison: number;

  /**
   * 价格
   */
  price_set: priceInterface
  /**
   * 折扣价
   */
  discount_price_set: priceInterface | null
  discount_style: DiscountStyleEnum | null

  has_discount_code?: boolean
}

/**
 * Plan List Request
 */
export interface PlanListRequest {
  plans: Plan[];
}

export interface CurrentSubscriptionResponse {
  current_subscription: CurrentSubscription;
}

export interface PlanListRequest {
  plans: Plan[];
}

export interface CurrentSubscriptionAndPlan {
  current_subscription: CurrentSubscription;
  plans: Plan[];
}

export interface QuotaReminder {
  count: string;
  email: string;
  type: QuotaReminderType;
}

export interface CurrentSubscription {
  version: number // 套餐版本 1为最老套餐，2为2023年11月前的套餐 3为最新套餐
  additional_quota_used: number;
  /**
   * 截止日期
   */
  expiration_date: string;

  /**
   * 结算周期结束日期
   */
  billing_cycle_end_date: string;
  interval: AppPricingInterval;
  next_plan: null | NextPlan;
  quota_reminder: null | QuotaReminder;
  plan_name: string;
  pricing_id: number;
  quota_remain: number;
  quota_total: number;
  trial_end_date: null | string;
  /**
   * 套餐等级，用于套餐分组
   */
  level: number;
  spending_limit: string
  additional_quota_price: string

  "pricing_update": {
    "discount_code_id": number,
    "discount_code": string,
    "display_banner": boolean,
    "latest_version": number
  } | null
}

export interface NextPlan {
  name: string;
  pricing_id: number;
  quota: number;
  /**
   * 套餐等级，用于套餐分组
   */
  level: number;

  version: number // 当前降得版本
}

export interface SubscriptionResponse {
  subscription: Subscription;
}

export interface Subscription {
  confirmation_url: string;
  created_at: string;
  current_period_end: null;
  id: number;
  name: string;
  price: string;
  return_url: string;
  status: string;
  test: boolean;
  trial_days: number;
}


export interface SubscriptionSpendingLimitResponse {
  subscription_spending_limit: {
    amount: string;
    confirmation_url: string | null;
  };
}


/**
 * 获取当前折扣码接口
 */
export interface CurrentDiscountCodeResponse {
  current_discount: null | CurrentDiscountCode;
}

/**
 * CurrentDiscountCode
 */
export interface CurrentDiscountCode {
  /**
   * 当前折扣码
   */
  code: string;
  /**
   * 折扣规则
   */
  rule?: DiscountRule;
}

/**
 * 折扣规则
 *
 * DiscountRule
 */
export interface DiscountRule {
  /**
   * 折扣码过期时间戳
   */
  ends_at: number;
  /**
   * 折扣码生效时间戳
   */
  starts_at: number;
}



