import { Action } from "@shopify/app-bridge/actions/Navigation/Redirect"
import { Button, Card, Icon, Link, Select, Text, TextField } from "@shopify/polaris"
import { EditIcon, SelectIcon } from "@shopify/polaris-icons"
import { Observer } from "mobx-react"
import React, { useEffect, useMemo, useState } from "react"
import { Trans, useTranslation } from "react-i18next"

import MultiPopup from "@/component/MultiPopup"
import CardTitle from "@/component/TitleGroup/CardTitle"
import stores from "@/stores"
import AppBridge from "@/utils/appBridge"


export const appProxySubpathPrefixList = ["apps", "a", "community", "tools"]

export default function TrackingPageURL() {
  const { t } = useTranslation("tracking_page")
  const { userInfoSore, trackingPageStore } = stores
  const { store_real_name = "" } = userInfoSore.data

  const PrefixOptions = useMemo(() => appProxySubpathPrefixList.map(prefix => ({
    label: `https://${store_real_name}/${prefix}/`,
    value: prefix,
  })), [store_real_name])

  const handlePrefixChange = (selected: string) => {
    trackingPageStore.setOption("trackingPageUrl", "subpathPrefix", selected)
  }

  const handleSubpathChange = (value: string) => {
    trackingPageStore.setOption("trackingPageUrl", "subpath", value ? value : "parcelpanel")
  }

  const [multiPopupShow, setMultiPopupShow] = useState(false)
  const [multiPopupIndex, setMultiPopupIndex] = useState(0)

  const [tempSubpathPrefix, setTempSubpathPrefix] = useState(trackingPageStore.trackingPageUrl?.subpathPrefix || "")
  const [tempSubpath, setTempSubpath] = useState(trackingPageStore.trackingPageUrl?.subpath || "")
  const [isUpdateInShopify, setIsUpdateInShopify] = useState(false)

  useEffect(() => {
    setTempSubpathPrefix(trackingPageStore.trackingPageUrl?.subpathPrefix || "")
    setTempSubpath(trackingPageStore.trackingPageUrl?.subpath || "")
    setIsUpdateInShopify(false)
  }, [multiPopupShow])

  return (
    <Observer>{() => {
      const { subpathPrefix, subpath } = stores.trackingPageStore.trackingPageUrl || {}
      const URLWill = `https://${store_real_name}/${subpathPrefix}/${subpath}`
      const TempURLWill = `https://${store_real_name}/${tempSubpathPrefix}/${tempSubpath}`

      const isChange = subpathPrefix !== tempSubpathPrefix || tempSubpath !== subpath
      // Next step1 默认为 Basic button，用户编辑 URL 后更改为 Primary button
      const step1Action = isChange ? {
        primaryAction: {
          disabled: tempSubpath === "",
          content: t("NextStep"),
          onAction: () => {
            setMultiPopupIndex(multiPopupIndex + 1)
          },
        },
      } : {
        secondaryActions: [
          {
            content: t("NextStep"),
            onAction: () => setMultiPopupIndex(multiPopupIndex + 1),
          },
        ],
      }
      // Next step2 默认为 Basic button，点击Update in Shopify settings按钮后更改为 Primary button
      const step2Action = isUpdateInShopify ? {
        primaryAction: {
          content: t("NextStep"),
          onAction: () => setMultiPopupIndex(multiPopupIndex + 1),
        },
      } : {
        secondaryActions: [
          {
            content: t("NextStep"),
            onAction: () => setMultiPopupIndex(multiPopupIndex + 1),
          },
        ],
      }

      return (
        <>
          <Card>
            <CardTitle title={t("TabPage.TrackingPageUrl")} description={<Trans
              ns="tracking_page"
              i18nKey="TrackingPageUrlSummary.description"
            >
              <Link
                url="https://docs.parcelpanel.com/shopify/tracking-page/change-url/"
                target="_blank"
                removeUnderline
              />
            </Trans>}
            />

            <div className={"pt-4"}>
              <p className="mb-1">{t("YourTrackingPageUrl.label")}</p>
              <div className={"flex gap-y-1 flex-col sm:flex-row gap-x-4 flex-wrap disableInput"}>
                <div className={"w-full sm:w-[300px] md:w-[380px] xl:w-[404px] max-w-full"}>
                  <TextField label={null} autoComplete="off"
                    readOnly
                    value={PrefixOptions.find(item => item.value === subpathPrefix)?.label}
                    suffix={<Icon source={SelectIcon} />}
                  />
                </div>
                <div className={"flex-1"}>
                  <TextField readOnly label={null} autoComplete="off" value={subpath} />
                </div>

                <div className={"w-8"}>
                  <Button icon={EditIcon} size={"large"} onClick={() => setMultiPopupShow(true)} />
                </div>
              </div>
              <div className={"mt-1"}>
                <Text as="span" tone="subdued">{t("TrackingPageUrlSummary.URLWill")} {URLWill}</Text>
              </div>

              <MultiPopup
                active={multiPopupIndex}
                open={multiPopupShow}
                onClose={() => setMultiPopupShow(false)}
                onChange={(index) => setMultiPopupIndex(index)}
                stackData={[
                  {
                    title: t("Step1Title"),
                    children: <>
                      <p className="mb-1">{t("EditTrackingPageURL")}</p>
                      <div className={"flex gap-y-1 flex-col sm:flex-row gap-x-3 flex-wrap"}>
                        <div className={"w-[388px] max-w-full"}>
                          <Select
                            label={null}
                            options={PrefixOptions}
                            value={tempSubpathPrefix}
                            onChange={(value) => setTempSubpathPrefix(value)}
                          />
                        </div>
                        <div className={"flex-1"}>
                          <TextField label={null} autoComplete="off"
                            value={tempSubpath}
                            placeholder="parcelpanel" onChange={(value) => setTempSubpath(value)} maxLength={25}
                          />
                        </div>
                      </div>
                      <div className={"mt-1"}>
                        <Text as="span" tone="subdued">{t("TrackingPageUrlSummary.URLWill")} {TempURLWill}</Text>
                      </div>
                    </>,
                    ...step1Action,
                  },
                  {
                    title: t("Step2Title"),
                    children: <div className={"flex gap-3 flex-col"}>
                      <Text variant={"bodyMd"} fontWeight={"medium"} as={"span"}>{t("Step2TitleDesc")}</Text>
                      <div>
                        <p className="mb-1">{t("Step2InputLabel")}</p>
                        <div className={"flex gap-y-1 flex-col sm:flex-row gap-x-3 flex-wrap"}>
                          <div className={"w-[388px] max-w-full disableInput"}>
                            <TextField label={null} autoComplete="off"
                              readOnly
                              value={PrefixOptions.find(item => item.value === tempSubpathPrefix)?.label}
                              suffix={<Icon source={SelectIcon} />}
                            />
                          </div>
                          <div className={"flex-1"}>
                            <TextField label={null} autoComplete="off"
                              readOnly
                              value={tempSubpath}
                              placeholder="parcelpanel" onChange={handleSubpathChange} maxLength={25}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={"w-[181px]"}>
                        <Button
                          fullWidth={false}
                          variant={isUpdateInShopify ? "secondary" : "primary"}
                          onClick={() => {
                            AppBridge.exist() && AppBridge.redirect.dispatch(Action.ADMIN_PATH, {
                              newContext: true,
                              path: `/settings/apps/app_installations/app/${import.meta.env.VITE_SHOPIFY_APP_KEY}`,
                            })
                            setTimeout(() => setIsUpdateInShopify(true), 300)
                          }}
                        >{t("UpdateInShopifySettings")}</Button>
                      </div>
                    </div>,
                    ...step2Action,
                  },
                  {
                    title: "Step 3: Edit tracking page URL",
                    children: <>
                      <Text variant={"bodyMd"} fontWeight={"medium"} as={"span"}>{t("Step3TitleDesc")}</Text>
                      <div className={"mt-3"}>{t("Step3Text1")}</div>
                      <div className={"mt-1"}>
                        <Text as="p" tone="subdued">
                          <Trans i18nKey={"Step3Text2"} ns={"tracking_page"}>
                            <Link target="_blank" url={"https://docs.parcelpanel.com/shopify/getting-started/add-the-tracking-page/"} />
                          </Trans>
                        </Text>
                      </div>
                    </>,
                    secondaryActions: [
                      {
                        content: t("Done"),
                        onAction: () => {
                          handleSubpathChange(tempSubpath)
                          handlePrefixChange(tempSubpathPrefix)
                          setMultiPopupShow(false)
                          setMultiPopupIndex(0)
                        },
                      },
                    ],
                  },
                ]}
              />
            </div>
          </Card>
        </>
      )}
    }</Observer>
  )
}
