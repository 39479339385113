import { Button, Modal } from "@shopify/polaris"
import type { ComplexAction } from "@shopify/polaris/build/ts/src/types"
import { ArrowLeftIcon } from "@shopify/polaris-icons"
import classNames from "classnames"

import styles from "./index.module.scss"

type stackDataItem = {
  title: string
  children?: string | React.ReactNode
  /** Primary action */
  primaryAction?: ComplexAction;
  /** Collection of secondary actions */
  secondaryActions?: ComplexAction[];
}

interface MultiPopupProps {
  /** 开启状态 */
  open: boolean
  /** 当前聚焦Index */
  active: number
  /** Modal数据堆栈 */
  stackData: stackDataItem[]
  /** 上一个 */
  onPrev?: (index: number) => void
  /** 下一个 */
  onNext?: (index: number) => void
  /** 关闭事件 */
  onClose: () => void
  /** 切换事件 */
  onChange: (index: number) => void
}

export default function ({
  open,
  active,
  onClose,
  onChange,
  stackData,
}: MultiPopupProps){
  const currentModal = stackData[active]

  const handleClose = () => {
    onClose()
    onChange(0)
  }

  return  <Modal
    open={open}
    onClose={handleClose}
    title={active === 0 ? currentModal.title : <div className={classNames("flex gap-2", styles.modalTitle)}>
      <Button variant="tertiary" icon={ArrowLeftIcon} onClick={() => onChange(active - 1)}></Button>
      {currentModal.title}
    </div>}
    primaryAction={currentModal.primaryAction}
    secondaryActions={currentModal.secondaryActions}
  >
    <Modal.Section>
      {currentModal.children}
    </Modal.Section>
  </Modal>
}
