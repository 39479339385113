import { getSessionToken } from "@shopify/app-bridge-utils"
import axios, { AxiosRequestConfig } from "axios"
import qs from "qs"

import { APICode } from "@/api/APICode"
import i18next, { t } from "@/i18n/config"
import stores from "@/stores"
import AppBridge from "@/utils/appBridge"
import toast from "@/utils/toast"



export interface IExtParam {
  // 判定取消类型方法
  cancelType?: CancelTypesEnum
}

export enum CancelTypesEnum {
  ALL, // 根据 method data 等判定重读请求
  PATH, // 根据路径判定重复请求
}

const ResponseErrorMessage: { [k: number]: string } = {
  400: "Bad request",
  401: "Unauthorized, please log in again",
  402: "Payment Required",
  403: "Access denied",
  404: "Request error, the resource was not found",
  405: "Request method not allowed",
  408: "Request timed out",
  500: "Server error, please try again or refresh the page",
  501: "The network is not implemented",
  502: "Network Error",
  503: "Service is not available",
  504: "Network timeout",
  505: "HTTP version does not support the request",
}

console.log(import.meta.env.VITE_API_BASE_URL_INS)
// axios 实例
const request = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL_INS,
  timeout: 18_000,
  // responseType: 'json'
})


// 添加请求拦截器
request.interceptors.request.use(
  request => {
    const extParam: IExtParam = {
      cancelType: request.data?.cancelType ?? CancelTypesEnum.ALL,
    }

    request.data && request.data?.cancelType && (delete request.data.cancelType)

    if (request.headers) {

      request.headers["Content-Type"] = "application/json;charset=utf-8"
      request.headers.Accept = "application/json"
    }

    removePending(request, extParam) // 在请求开始前，对之前的请求做检查取消操作
    addPending(request, extParam) // 将当前请求添加到 pending 中

    // 如果是从超管后台进入
    const { authToken } = stores.commonStore

    console.log(authToken, "店铺uid")

    if (authToken) {
      if (request.headers) {
        request.headers.Authorization = `Bearer ${authToken}`
      }

      return request
    }

    // 如果是开发环境
    if (!AppBridge.exist()) {
      setFirstConsole(`-->APP Bridge 不存在: ${authToken}`)
      return request
    }

    return getSessionToken(AppBridge.app)
      .then((token: string) => {
        if (request.headers) {
          request.headers.Authorization = `Bearer ${token}`
          setFirstConsole(`-->存在 APP Bridge: ${token}`)
        }
        return request
      })
  },
  error => {
    return Promise.reject(error)
  },
)

// 添加响应拦截器
request.interceptors.response.use(
  response => {
    // 关闭加载
    removePending(response.config)

    return response
  },
  error => {
    // 关闭加载
    if (axios.isCancel(error)) {
      // 中断 Promise 调用链
      return new Promise(() => void 0)
    }

    const { response } = error

    // 根据返回的http状态码做不同的处理 response?.status
    if (response?.status) {
      error.msg = ResponseErrorMessage[response.status] || `${t("common:ErrorInConnecting")}: ${response.status}`
    } else {
      error.msg = "连接错误, 请刷新页面"
      error.msg = "Connection error, please refresh the page"
      console.error(error)
    }

    // 弹出错误提示
    toast(error.msg, 5e3, true)

    if (error.data) {
      error.data.status = "fail"
      error.data.msg = error.msg
    }

    return Promise.reject(response || { message: error.message })
  },
)

export default request

// ///////////////////////////////////////////////////////////
// ////////////////////////取消重复请求配置/////////////////////
// ///////////////////////////////////////////////////////////

// 声明一个 Map 用于存储每个请求的标识 和 取消函数
const pending = new Map()

/**
 * 添加请求
 * @param {Object} config
 * @param extParam
 */
const addPending = (config: AxiosRequestConfig, extParam?: IExtParam) => {
  const url = [
    config.method,
    config.url,
    qs.stringify(config.params),
  ]

  // 如果取消的类型是 ALL 那么就要把 POST 参数加上
  // @ts-ignore
  if (extParam?.cancelType && extParam.cancelType === CancelTypesEnum.ALL) {
    url.push(qs.stringify(config.data))
  }

  const urlString: string = url.join("&")

  config.cancelToken = config.cancelToken || new axios.CancelToken(cancel => {
    if (!pending.has(urlString)) { // 如果 pending 中不存在当前请求，则添加进去
      pending.set(urlString, cancel)
    }
  })
}

/**
 * 移除请求
 * @param {Object} config
 * @param extParam
 */
const removePending = (config: AxiosRequestConfig, extParam?: IExtParam) => {
  const url = [
    config.method,
    config.url,
    qs.stringify(config.params),
  ]

  // 如果取消的类型是 ALL 那么就要把 POST 参数加上
  // @ts-ignore
  if (extParam?.cancelType && extParam.cancelType === CancelTypesEnum.ALL) {
    url.push(qs.stringify(config.data))
  }

  const urlString: string = url.join("&")

  if (pending.has(urlString)) { // 如果在 pending 中存在当前请求标识，需要取消当前请求，并且移除
    const cancel = pending.get(urlString)

    cancel(urlString)
    pending.delete(urlString)
  }
}


// 是否为首次加载
let firstConsole = true

function setFirstConsole(log: string) {
  if (firstConsole) console.log(`%c${log}`, "background-color:#007557; color: white; padding: 4px 8px;")
  firstConsole = false
}
